import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Chip, CircularProgress, Paper, Stack, useTheme } from "@mui/material";
import { DiagonalFillPattern } from "assets/svgs";
import { DEFAULT_TIME_FILTER } from "common/molecules/TimeFilter/TimeFilter";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { Operator } from "modules/facets/types";
import { getFilteredMetadata } from "modules/scope-metadata/utils";
import { useSearchStore } from "modules/search/store";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { CompassDirection, PathFilters, PathStatus } from "pages/paths/types";
import { useTagsAPI } from "pages/tags/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { Edge, Node } from "reactflow";
import "reactflow/dist/style.css";
import { CTNodeManager } from "./components/CTNodeManager";
import EdgeWithButton, { EdgeDataType } from "./components/EdgeWithButton";
import { ReactFlowContainer } from "./components/ReactFlowContainer";
import {
	TrafficVisxToolbar,
	TrafficVisxToolbarProps,
} from "./components/TrafficVisxToolbar";
import { Visualizer } from "./components/Visualizer";
import { TrafficVisualizerDrawer } from "./components/controlled-drawer";
import { TrafficVisualizerDrawerProps } from "./components/controlled-drawer/TrafficVisualizerDrawer";
import { EdgeDetailsReviewDrawer } from "./components/egde-details-review-drawer";
import { useAssetDetailCommonStore as useReviewStore } from "./components/egde-details-review-drawer/store/common/useAssetDetailCommonStore";
import { isSupportedFilterType } from "./components/source-node/PathsLegendsGroup";
import { useDirectionChange } from "./hooks/useDirectionChange";
import { useNodesAndEdges } from "./hooks/useNodesAndEdges";
import { HUB_TAG, NOT_HUB_TAG } from "./hooks/useTrafficData";
import {
	DEFAULT_HUB_CRITERIA,
	useVisualizerTrafficCriteriaBuilder,
} from "./hooks/useVisualizerCriteriaBuilder";
import { useVisualizerData } from "./hooks/useVisualizerData";
import { useSourceFacetStore, useVisxStore } from "./store";
import {
	CTNodeType,
	Dimensions,
	GroupNodeDataType,
	PathReviewStatus,
	PathType,
	PathTypes,
	StatisticType,
	TrafficDatum,
	TrafficVisualizerProps,
	TypeDimension,
	UserGroupDimension,
} from "./types";
import {
	DEFAULT_INTERNET_NN_NAME,
	DEFAULT_INTRANET_NN_NAME,
	DEFAULT_PRIVATE_NETWORK_NODE_NAME,
	DEFAULT_PUBLIC_NETWORK_NODE_NAME,
	NN_TITLE,
	getCriteriaForNode,
	getSubnetField,
	isSubnet,
	joinCriterias,
	withHub,
	withoutHub,
} from "./visx-utils";

const proOptions = { hideAttribution: true };

const nodeTypes = {
	ctNode: CTNodeManager,
};

const defaultEdges: Array<Edge<EdgeDataType>> = [];

const defaultNodes: Array<Node<GroupNodeDataType>> = [];

const edgeTypes = { edgeWithButton: EdgeWithButton };

export function TrafficVisualizer(props: TrafficVisualizerProps) {
	const theme = useTheme();
	const search = useSearchStore(state => state.search);

	const sourceAssetFilters = useSourceFacetStore(state => state.facets);
	const updateSourceAssetFacet = useSourceFacetStore(
		state => state.updateFacet
	);

	const selectedDirection = useVisxStore(state => state.selectedDirection);
	const setSelectedDirection = useVisxStore(
		state => state.setSelectedDirection
	);

	const selectedDimension = useVisxStore(state => state.selectedDimension);

	const { tagFields: tags, metaData: assetTagsMetadata } = useTagsAPI();

	const userDefinedTagsList: {
		label: string;
		name: string;
		dataType: string;
	}[] =
		tags?.userDefinedTags?.map(coreTag => ({
			label: coreTag.displayName,
			name: coreTag?.name || "",
			dataType: coreTag.dataType,
		})) ?? [];

	let dimensionOptions = [
		...getFilteredMetadata({
			columns: Dimensions,
			metadata: assetTagsMetadata,
		}),
		...userDefinedTagsList,
	]?.sort((a, b) => a.label.localeCompare(b.label));

	dimensionOptions.push(UserGroupDimension);

	const setSelectedPathStatus = useVisxStore(
		state => state.setSelectedPathStatus
	);

	const enabledStatus = useVisxStore(state => state.enabledStatus);
	const setStatusEnabledMap = useVisxStore(state => state.setStatusEnabledMap);

	const setStatusEnabled = (status: PathTypes, enabled: boolean) => {
		setStatusEnabledMap(old => {
			let newValue = new Map(old);
			switch (status) {
				case PathStatus.Allow:
					newValue.set(PathStatus.Allow, enabled);
					newValue.set(PathStatus.AllowedByTemplate, enabled);
					newValue.set(PathStatus.AllowedByProgressive, enabled);
					break;
				case PathStatus.Deny:
					newValue.set(PathStatus.Deny, enabled);
					newValue.set(PathStatus.DeniedByTemplate, enabled);
					break;
				case PathStatus.AllowedByTestUIOnly:
					newValue.set(PathStatus.AllowTestDenied, enabled);
					newValue.set(PathStatus.AllowTestDeniedViolation, enabled);
					break;
				default:
					newValue.set(status, enabled);
					break;
			}
			newValue.set(status, enabled);
			return newValue;
		});
	};

	const {
		destinationCriteria,
		sourceCriteria,
		trafficCriteria,
		hubCriteria,
		metaData: assetMetadata,
		isLoading: metadataQueryLoading,
		direction,
	} = useVisualizerTrafficCriteriaBuilder({
		search,
		selectedDirection,
		sourceAssetFilters,
	});
	useEffect(() => {
		setSelectedDirection(direction);
	}, [direction, setSelectedDirection]);

	const isReady = useRef(false);

	const setTrafficData = useVisxStore(state => state.setTrafficData);

	let selectedSourceDimension = selectedDimension;
	let selectedDestinationDimension = selectedDimension;

	if (hubCriteria !== DEFAULT_HUB_CRITERIA) {
		selectedSourceDimension =
			selectedDirection === Direction.Inbound
				? TypeDimension
				: selectedDimension;
		selectedDestinationDimension =
			selectedDirection === Direction.Inbound
				? selectedDimension
				: TypeDimension;
	}

	const {
		isLoading: isHubLoading,
		reload: reloadHub,
		isEmpty: isHubEmpty,
	} = useVisualizerData({
		destinationCriteria:
			hubCriteria === DEFAULT_HUB_CRITERIA ? destinationCriteria : hubCriteria,
		sourceCriteria:
			hubCriteria === DEFAULT_HUB_CRITERIA ? sourceCriteria : hubCriteria,
		trafficCriteria,
		selectedSourceDimension: selectedDimension,
		selectedDestinationDimension: selectedDimension,
		syncWithStore: true,
		tag: hubCriteria === DEFAULT_HUB_CRITERIA ? "main" : HUB_TAG,
		loadAssets: true,
	});

	const nonHubLoadParams = {
		destinationCriteria:
			hubCriteria !== DEFAULT_HUB_CRITERIA ? destinationCriteria : undefined,
		sourceCriteria:
			hubCriteria !== DEFAULT_HUB_CRITERIA ? sourceCriteria : undefined,
		trafficCriteria,
		selectedSourceDimension,
		selectedDestinationDimension,
		syncWithStore: true,
		tag: NOT_HUB_TAG,
		loadAssets: false,
	};

	const {
		isLoading: isNonHubLoading,
		reload: reloadNonHub,
		isEmpty: isNonHubEmpty,
	} = useVisualizerData(nonHubLoadParams);

	const isLoading = isNonHubLoading || isHubLoading;
	const reload = () => {
		reloadHub();
		reloadNonHub();
		if (selectedPathStatus === PathReviewStatus.Enforced) {
			setSelectedPathStatus(PathReviewStatus.WIP);
		}
	};

	const isEmpty =
		isHubEmpty &&
		(nonHubLoadParams.sourceCriteria ? isNonHubEmpty : isHubEmpty);

	const resetExpansions = useVisxStore(state => state.resetExpansions);
	const resetHierarchy = useVisxStore(state => state.resetHierarchy);
	useEffect(() => {
		if (isReady.current) {
			resetExpansions();
			resetHierarchy();
		}
	}, [selectedDimension, hubCriteria, resetExpansions, resetHierarchy]);

	useEffect(() => {
		resetHierarchy();
	}, [isLoading, resetHierarchy]);

	useEffect(() => {
		if (
			trafficCriteria &&
			(sourceCriteria || destinationCriteria) &&
			selectedDimension &&
			setTrafficData &&
			isReady.current
		) {
			setTrafficData(undefined);
			console.log("Resetting traffic data");
		}
	}, [
		trafficCriteria,
		sourceCriteria,
		destinationCriteria,
		selectedDimension,
		setTrafficData,
		selectedDirection,
	]);

	const trafficData = useVisxStore(state => state.trafficData);

	const [portFilter, setPortFilter] = useState<PathFilters | undefined>(
		undefined
	);
	const [pathFilter, setPathFilter] = useState<PathFilters | undefined>(
		undefined
	);

	const [selectedPathType, setSelectedPathType] = useState<PathType>(
		PathType.None
	);

	const [nodes, setNodes] = useState(defaultNodes);
	const nodesRef = useRef(nodes);
	nodesRef.current = nodes;

	const [edges, setEdges] = useState(defaultEdges);
	const edgesRef = useRef(edges);
	edgesRef.current = edges;

	const parentIds = useVisxStore(state => state.parentIds);
	const { onDirectionChange } = useDirectionChange();
	const setSelectedDimension = useVisxStore(
		state => state.setSelectedDimension
	);

	const restore = useVisxStore(state => state.restore);

	const setSavedTimeState = useUserPreferencesStore(
		state => state.setSavedTimeState
	);
	const setTimeFilter = useUserPreferencesStore(state => state.setTimeFilter);

	const onEdgeClick = useCallback(
		(e: any, edge: Edge<EdgeDataType>) => {
			if (!trafficData) {
				return;
			}

			let trafficInfo = edge.data?.trafficInfo;
			if (!trafficInfo || edge.data?.interactive === false) {
				return;
			}

			let nodes = nodesRef.current;
			let source: TrafficDatum | undefined = trafficData[edge.source];
			let target: TrafficDatum | undefined = trafficData[edge.target];

			if (!source && !target) {
				return;
			}

			let isUsrGroupExpanded =
				source?.sourceDimension?.label === "User Group" ||
				target?.sourceDimension?.label === "User Group";

			const getCriteria = (id: string, isNested: boolean = false) => {
				let node = nodes.find(node => node.id === id);
				if (!node) {
					return "*";
				}
				let data = node.data;
				if (!data) {
					// Connector node or named network
					return "*";
				}

				if (data.title === NN_TITLE) {
					if (
						data.label === DEFAULT_PUBLIC_NETWORK_NODE_NAME ||
						data.label === DEFAULT_PRIVATE_NETWORK_NODE_NAME
					) {
						return "assetid=NULL";
					}

					if (isSubnet(data.id)) {
						let subnetField = getSubnetField(data.id);
						return `assetid=NULL AND '${subnetField}'='${data.label}'`;
					}
					return `assetid=NULL AND namednetworkname in ('${data.id}')`;
				}
				let criteria = getCriteriaForNode(data.id, data.dimension);

				let parent = data.trafficData?.parent?.name || parentIds.get(id);

				let parentJoins = [];
				if (parent) {
					let parentCriteria = getCriteria(parent, true);
					parentJoins.push(parentCriteria);
				}

				if (parentJoins.length) {
					criteria = joinCriterias([criteria, ...parentJoins]);
				}

				if (
					!isNested &&
					hubCriteria !== "*" &&
					hubCriteria !== DEFAULT_HUB_CRITERIA &&
					!isUsrGroupExpanded
				) {
					if (node.data.type === CTNodeType.HUB) {
						criteria = withHub(criteria, hubCriteria);
					} else {
						criteria = withoutHub(criteria, hubCriteria);
					}
				}
				return criteria;
			};

			let srcCriteria = getCriteria(edge.source, false);
			let dstCriteria = getCriteria(edge.target, false);

			const isEastWestOtherIps =
				edge.source === DEFAULT_INTRANET_NN_NAME ||
				edge.target === DEFAULT_INTRANET_NN_NAME;

			const isNorthSouthOtherIps =
				edge.source === DEFAULT_INTERNET_NN_NAME ||
				edge.target === DEFAULT_INTERNET_NN_NAME;

			let tFilterAppend = `compassdirection='${edge.data?.trafficInfo?.compassDirection}'`;

			let tFilter = trafficCriteria || "*";
			if (isEastWestOtherIps || isNorthSouthOtherIps) {
				const unmanagedOtherTrafficFilter =
					"namednetworkname = NULL AND assetid = NULL";
				if (selectedDirection === Direction.Inbound) {
					srcCriteria = unmanagedOtherTrafficFilter;
				} else {
					dstCriteria = unmanagedOtherTrafficFilter;
				}
			}

			if (tFilterAppend) {
				if (trafficCriteria === "*") {
					tFilter = tFilterAppend;
				} else {
					tFilter = tFilter + " AND " + tFilterAppend;
				}
			}

			if (trafficInfo.compassDirection === CompassDirection.NorthSouth) {
				setSelectedPathType(PathType.Internet);
			} else {
				setSelectedPathType(PathType.Intranet);
			}

			if (selectedDirection !== Direction.Outbound) {
				setPortFilter({
					criteria: tFilter,
					srcCriteria,
					dstCriteria,
				});
			}

			setPathFilter({
				criteria: tFilter,
				srcCriteria,
				dstCriteria,
			});
		},
		[trafficData, hubCriteria, trafficCriteria, parentIds, selectedDirection]
	);

	useNodesAndEdges({
		setNodes,
		setEdges,
		enabledStatus,
		onEdgeClick,
		trafficData,
	});

	const restoreState = () => {
		let savedState = useVisxStore.getState().savedState;
		const savedTime = useUserPreferencesStore.getState().savedTimeState;
		if (savedState) {
			savedState.savedState = undefined;
			restore(savedState);
			onDirectionChange(Number(savedState?.selectedDirection) as Direction);
		} else {
			setSelectedDimension(Dimensions[0]);
		}
		if (savedTime) {
			setSavedTimeState(DEFAULT_TIME_FILTER);
			setTimeFilter(savedTime);
		}
	};

	const renderPlaceHolder = () => {
		return (
			<Stack
				flex={1}
				sx={{ height: "100%" }}
				alignItems={"center"}
				justifyContent="center"
			>
				{isLoading || !isEmpty || metadataQueryLoading ? (
					<CircularProgress size={24} />
				) : (
					<Chip
						label={window.getCTTranslatedText("No data")}
						sx={{
							cursor: "move",
							backgroundColor:
								theme.palette.mode === "dark"
									? theme.palette.grey[700]
									: theme.palette.grey[200],
						}}
						icon={
							selectedDimension?.name === UserGroupDimension.name ? (
								<ArrowBackIcon
									sx={{
										cursor: "pointer",
									}}
									onClick={() => restoreState()}
								/>
							) : (
								<></>
							)
						}
					/>
				)}
			</Stack>
		);
	};

	const onCloseEdgeDetailsDrawer = () => {
		setPortFilter(undefined);
		setPathFilter(undefined);
		setSelectedPathType(PathType.None);
	};

	const hasNodes = nodes.length;

	const hasNodesAndData = hasNodes && !isLoading;

	if (hasNodesAndData) {
		isReady.current = true;
	}

	const toolbarProps: TrafficVisxToolbarProps = {
		hubCriteria,
		sourceAssetFacet: sourceAssetFilters,
		updateSourceAssetFacet,
		setStatusEnabled,
		enabledStatus,
		assetMetadata,
		dimensionOptions,
	};

	const drawerProps: TrafficVisualizerDrawerProps = {
		hubCriteria,
		assetMetadata,
	};

	const updateStatusFacet = useReviewStore(state => state.updateFacet);

	const selectedPathStatus = useVisxStore(state => state.selectedPathStatus);
	useEffect(() => {
		const statusOptions = new Map();
		enabledStatus.forEach((value, key) => {
			statusOptions.set(key, { isSelected: value, operator: Operator.EQUAL });
			statusOptions.delete("Mixed");
			statusOptions.delete(PathStatus.AllowedByTestUIOnly);

			if (!isSupportedFilterType(selectedPathStatus, key)) {
				statusOptions.delete(key);
			}
		});

		const isSimulationOn = selectedPathStatus !== PathReviewStatus.Enforced;
		if (enabledStatus.get(PathStatus.Allow) !== true) {
			statusOptions.delete(PathStatus.AllowedByProgressive);
			statusOptions.delete(PathStatus.AllowedByTemplate);
		} else {
			statusOptions.set(PathStatus.AllowedByTemplate, {
				isSelected: true,
				operator: Operator.EQUAL,
			});
			statusOptions.set(PathStatus.AllowedByProgressive, {
				isSelected: true,
				operator: Operator.EQUAL,
			});
		}

		if (enabledStatus.get(PathStatus.AllowedByTestUIOnly) !== true) {
			statusOptions.delete(PathStatus.AllowTestDenied);
			statusOptions.delete(PathStatus.AllowTestDeniedViolation);
		} else {
			if (!isSimulationOn) {
				statusOptions.set(PathStatus.AllowTestDenied, {
					isSelected: true,
					operator: Operator.EQUAL,
				});
				statusOptions.set(PathStatus.AllowTestDeniedViolation, {
					isSelected: true,
					operator: Operator.EQUAL,
				});
			} else {
				// simulation
				statusOptions.set(PathStatus.Deny, {
					isSelected: true,
					operator: Operator.EQUAL,
				});
			}
		}

		if (enabledStatus.get(PathStatus.Deny) !== true) {
			statusOptions.delete(PathStatus.DeniedByTemplate);
		} else {
			statusOptions.set(PathStatus.DeniedByTemplate, {
				isSelected: true,
				operator: Operator.EQUAL,
			});
		}

		let hasAll = true;
		[PathStatus.Allow, PathStatus.Deny, PathStatus.Unreviewed].map(
			status => (hasAll = hasAll && enabledStatus.get(status) === true)
		);

		if (hasAll) {
			statusOptions.clear();
		}

		let facetName =
			selectedPathStatus === PathReviewStatus.Enforced
				? StatisticType.Enforced
				: StatisticType.Candidate;

		let statuses = Object.values(StatisticType).filter(
			status => status !== facetName
		);

		updateStatusFacet({
			facetName: facetName,
			options: statusOptions,
		});

		statuses.forEach(status => {
			updateStatusFacet({
				facetName: status,
				options: new Map(),
			});
		});
	}, [enabledStatus, selectedPathStatus, updateStatusFacet]);

	return (
		<Stack sx={{ height: "100%" }}>
			<TrafficVisxToolbar {...toolbarProps} />
			<ReactFlowContainer id="visualizer-container">
				<Paper
					elevation={theme.palette.mode === "dark" ? 6 : 1}
					sx={{
						height: "100%",
						flex: 1,
						borderRadius: 0,
						background: theme.palette.background.paper,
					}}
				>
					{hasNodesAndData ? (
						<Stack direction={"row"} sx={{ height: "100%", flex: 1 }}>
							<Visualizer
								fitView
								nodeTypes={nodeTypes}
								edgeTypes={edgeTypes}
								nodes={nodes}
								edges={edges}
								onEdgeClick={onEdgeClick}
								nodesDraggable={true}
								nodesConnectable={false}
								proOptions={proOptions}
								maxZoom={1}
								minZoom={0.8}
							/>

							<TrafficVisualizerDrawer {...drawerProps} />
						</Stack>
					) : (
						renderPlaceHolder()
					)}
				</Paper>

				<EdgeDetailsReviewDrawer
					pathType={selectedPathType}
					pathFilter={pathFilter}
					portFilter={portFilter}
					isOpen={Boolean(selectedPathType !== PathType.None)}
					onClose={onCloseEdgeDetailsDrawer}
					reload={reload}
					activeTab={
						selectedPathType === PathType.Internet && Boolean(portFilter)
							? 0
							: 1
					}
					pathReviewState={
						selectedPathStatus === PathReviewStatus.Enforced
							? "enforced"
							: "reviewed"
					}
				/>
				<DiagonalFillPattern
					opacity={0.2}
					strokeColor={theme.palette.grey[800]}
				/>
			</ReactFlowContainer>
		</Stack>
	);
}
