import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { IP_FIELD_TYPES } from "common/atoms/ct-ip-input/CTIPInput";
import { FormSectionHeader } from "common/atoms/form-section-header";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import React from "react";
import {
	DHCPModes,
	DHCPOptions,
	HAModes,
	MTUOptions,
	NetworkInterfaces,
	VLAN,
} from "../types";
import { IPWithMask_PLACEHOLDER, IP_PLACEHOLDER } from "../utils";
import { DHCP } from "./DHCP";

const baseRowStyle = {
	pt: 0,
	pb: 1.75,
	px: 3,
};

const rulesSectionWrapper = {
	py: 1,
	borderRadius: 1,
};

export interface ProtectedNetworkInterfaceProps {
	HAMode: string;
	DHCPMode: string;
	onDHCPModeChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	DHCPOption: string;
	onDHCPOptionChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	deviceIdentifier: string;
	setDeviceIdentifier: (value: string) => void;
	leaseDuration: string;
	setLeaseDuration: (value: string) => void;
	serverIp: string;
	setServerIp: (value: string) => void;
	networkInterface: string;
	onNetworkInterfaceChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	lanIP: string;
	setLanIP: (value: string) => void;
	lanStartIP: string;
	setLanStartIP: (value: string) => void;
	lanEndIP: string;
	setLanEndIP: (value: string) => void;
	lanVirtualIP: string;
	setLanVirtualIP: (value: string) => void;
	lanMTU: string;
	setLanMTU: (value: string) => void;
	VlanList: VLAN[];
	setVlanList: (VlanList: VLAN[]) => void;
	keyListener: (event: any) => void;
	isFormDisabled: boolean;
}

export function isDHCPEnabled(dhcpOption: string) {
	if (dhcpOption === DHCPOptions.DHCP || dhcpOption === DHCPOptions.Mixed) {
		return true;
	}
	return false;
}

export function ProtectedNetworkInterface({
	DHCPMode,
	onDHCPModeChange,
	DHCPOption,
	onDHCPOptionChange,
	deviceIdentifier,
	setDeviceIdentifier,
	leaseDuration,
	setLeaseDuration,
	serverIp,
	setServerIp,
	networkInterface,
	onNetworkInterfaceChange,
	lanIP,
	setLanIP,
	lanStartIP,
	setLanStartIP,
	lanEndIP,
	setLanEndIP,
	lanVirtualIP,
	setLanVirtualIP,
	HAMode,
	lanMTU,
	setLanMTU,
	VlanList,
	setVlanList,
	keyListener,
	isFormDisabled,
}: ProtectedNetworkInterfaceProps) {
	const handleVlanFieldChange = (e: TextFieldUpdate, index: number) => {
		if (e?.value !== undefined) {
			const value = e?.value.trim() || "";
			const field = e?.field as keyof VLAN;
			let newVlanList = [...VlanList];
			newVlanList[index][field] = value;
			setVlanList(newVlanList);
		}
	};

	const addNewVLAN = () => {
		const newVlanList = [...VlanList];
		newVlanList.push({
			id: "",
			ip: "",
			gatewayIP: "",
			startIP: "",
			endIP: "",
			virtualIP: "",
			mtu: "1500",
		});
		setVlanList(newVlanList);
		setTimeout(() => {
			scrollToLastVLAN(newVlanList?.length);
		});
	};

	const scrollToLastVLAN = (vlanLength: number) => {
		const element = document.getElementById(`vlan-item-${vlanLength}`);
		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	};

	const removeVLAN = (index: number) => {
		const newVlanList = [...VlanList];
		newVlanList.splice(index, 1);
		setVlanList(newVlanList);
	};

	function renderLANForm() {
		return (
			<Stack spacing={2}>
				<Grid
					container
					columnGap={4}
					rowGap={2}
					sx={{ pl: 0 }}
					display="grid"
					gridTemplateColumns="1fr 1fr"
				>
					<Stack>
						<CTIPInput
							field="lanIP"
							displayName={window.getCTTranslatedText("IPAddress")}
							placeholder={IPWithMask_PLACEHOLDER}
							fieldType={IP_FIELD_TYPES.SUBNET}
							value={lanIP}
							handleUpdate={(event: TextFieldUpdate) => setLanIP(event?.value)}
							disabled={isFormDisabled}
							onKeyUp={keyListener}
						/>
					</Stack>
					{isDHCPEnabled(DHCPOption) && DHCPMode === DHCPModes.Server ? (
						<>
							<Stack width="100%">
								<CTIPInput
									field={"startIP"}
									displayName={window.getCTTranslatedText("startIP")}
									placeholder={IP_PLACEHOLDER}
									value={lanStartIP}
									handleUpdate={(event: TextFieldUpdate) =>
										setLanStartIP(event?.value)
									}
									disabled={isFormDisabled}
									onKeyUp={keyListener}
								/>
							</Stack>
							<Stack width="100%">
								<CTIPInput
									field={"endIP"}
									displayName={window.getCTTranslatedText("endIP")}
									placeholder={IP_PLACEHOLDER}
									value={lanEndIP}
									handleUpdate={(event: TextFieldUpdate) =>
										setLanEndIP(event?.value)
									}
									disabled={isFormDisabled}
									onKeyUp={keyListener}
								/>
							</Stack>
						</>
					) : null}
					<Stack>
						<CTSelectDropDown
							field={"lanMTU"}
							displayName={window.getCTTranslatedText("MTU")}
							selectedValue={lanMTU}
							handleUpdate={(event: TextFieldUpdate) => setLanMTU(event.value)}
							data={MTUOptions}
							showLabel={true}
						/>
					</Stack>
					{HAMode === HAModes.Primary || HAMode === HAModes.Standby ? (
						<Stack>
							<CTIPInput
								field="lanVirtualIP"
								displayName={window.getCTTranslatedText("virtualIP")}
								placeholder={IP_PLACEHOLDER}
								value={lanVirtualIP}
								handleUpdate={(event: TextFieldUpdate) =>
									setLanVirtualIP(event?.value)
								}
								disabled={isFormDisabled}
								onKeyUp={keyListener}
							/>
						</Stack>
					) : null}
				</Grid>
			</Stack>
		);
	}

	function renderVLANForm() {
		return (
			<Box sx={{ minWidth: "100%" }}>
				<Stack
					alignItems="flex-start"
					justifyContent="flex-start"
					spacing={0}
					sx={{
						...rulesSectionWrapper,
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent={"space-between"}
						sx={{ width: "100%", my: 2 }}
					>
						<Stack alignItems="center">
							<Typography
								variant="subtitle1"
								sx={{
									color: theme => theme.palette.text.disabled,
								}}
							>
								{window.getCTTranslatedText("VLAN Interfaces")}
							</Typography>
						</Stack>
						<Stack alignItems="center">
							<Button
								variant="text"
								startIcon={<AddCircleOutlineIcon />}
								color="primary"
								onClick={() => addNewVLAN()}
								disabled={isFormDisabled}
							>
								{window.getCTTranslatedText("Add VLAN Interface")}
							</Button>
						</Stack>
					</Stack>
					<Stack sx={{ width: "100%" }}>
						{VlanList &&
							VlanList.length > 0 &&
							VlanList.map((item, index) => {
								const showLabel = true;
								return (
									<Stack
										direction={"row"}
										key={`ip-${index}`}
										id={`vlan-item-${index + 1}`}
										alignItems="center"
										sx={{
											background: theme =>
												theme.palette.mode === "dark"
													? theme.palette.background.paper
													: theme.palette.custom.lightGreyBg,
										}}
										mb={2}
									>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignContent="center"
											spacing={2}
											sx={{
												...baseRowStyle,
												width: "90%",
											}}
										>
											<Stack>
												<CTInputField
													field={"id"}
													displayName={window.getCTTranslatedText("ID")}
													value={item?.id}
													handleUpdate={(event: TextFieldUpdate) =>
														handleVlanFieldChange(event, index)
													}
													showLabel={showLabel}
													onKeyUp={keyListener}
													disabled={isFormDisabled}
												/>
											</Stack>
											<Stack>
												<CTIPInput
													field={"ip"}
													displayName={window.getCTTranslatedText("IPAddress")}
													fieldType={IP_FIELD_TYPES.SUBNET}
													value={item?.ip}
													placeholder={IPWithMask_PLACEHOLDER}
													handleUpdate={(event: TextFieldUpdate) =>
														handleVlanFieldChange(event, index)
													}
													showLabel={showLabel}
													onKeyUp={keyListener}
													disabled={isFormDisabled}
												/>
											</Stack>
											{isDHCPEnabled(DHCPOption) &&
											DHCPMode === DHCPModes.Server ? (
												<>
													<Stack>
														<CTIPInput
															field={"startIP"}
															displayName={window.getCTTranslatedText(
																"startIP"
															)}
															placeholder={IP_PLACEHOLDER}
															value={item?.startIP}
															handleUpdate={(event: TextFieldUpdate) =>
																handleVlanFieldChange(event, index)
															}
															showLabel={showLabel}
															onKeyUp={keyListener}
															disabled={isFormDisabled}
														/>
													</Stack>
													<Stack>
														<CTIPInput
															field={"endIP"}
															displayName={window.getCTTranslatedText("endIP")}
															placeholder={IP_PLACEHOLDER}
															value={item?.endIP}
															handleUpdate={(event: TextFieldUpdate) =>
																handleVlanFieldChange(event, index)
															}
															showLabel={showLabel}
															onKeyUp={keyListener}
															disabled={isFormDisabled}
														/>
													</Stack>
												</>
											) : null}
											{HAMode === HAModes.Primary ||
											HAMode === HAModes.Standby ? (
												<Stack>
													<CTIPInput
														field={"virtualIP"}
														displayName={window.getCTTranslatedText(
															"virtualIP"
														)}
														placeholder={IP_PLACEHOLDER}
														value={item?.virtualIP}
														handleUpdate={(event: TextFieldUpdate) =>
															handleVlanFieldChange(event, index)
														}
														showLabel={showLabel}
														onKeyUp={keyListener}
														disabled={isFormDisabled}
													/>
												</Stack>
											) : null}
											<Stack>
												<CTSelectDropDown
													field={"mtu"}
													displayName={window.getCTTranslatedText("MTU")}
													selectedValue={item?.mtu}
													handleUpdate={(event: TextFieldUpdate) =>
														handleVlanFieldChange(event, index)
													}
													data={MTUOptions}
													showLabel={true}
													disabled={isFormDisabled}
												/>
											</Stack>
										</Stack>
										<Stack
											justifyContent="center"
											alignItems="center"
											width="10%"
										>
											<IconButton
												size="small"
												aria-label="Remove VLAN"
												onClick={() => removeVLAN(index)}
												disabled={isFormDisabled}
											>
												<RemoveCircleOutlineIcon fontSize="small" />
											</IconButton>
										</Stack>
									</Stack>
								);
							})}
					</Stack>
				</Stack>
			</Box>
		);
	}

	return (
		<Box sx={{ minWidth: "100%", mt: 8 }}>
			<FormSectionHeader
				header={window.getCTTranslatedText("protectedNetworkInterface")}
			/>
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<DHCP
							DHCPOption={DHCPOption}
							onDHCPOptionChange={onDHCPOptionChange}
							DHCPMode={DHCPMode}
							onDHCPModeChange={onDHCPModeChange}
							deviceIdentifier={deviceIdentifier}
							setDeviceIdentifier={setDeviceIdentifier}
							leaseDuration={leaseDuration}
							setLeaseDuration={setLeaseDuration}
							serverIp={serverIp}
							setServerIp={setServerIp}
							keyListener={keyListener}
							isFormDisabled={isFormDisabled}
						/>
						<Stack justifyContent="center" minHeight={58}>
							<RadioGroup
								row
								aria-labelledby="select-source-destination-label"
								name="path-direction-radio-buttons-group"
								defaultValue={NetworkInterfaces.LAN}
								value={networkInterface}
								onChange={onNetworkInterfaceChange}
							>
								<FormControlLabel
									value={NetworkInterfaces.LAN}
									control={<Radio size="small" />}
									label={window.getCTTranslatedText(
										DisplayTextMap[NetworkInterfaces.LAN]
									)}
									disabled={isFormDisabled}
								/>
								<FormControlLabel
									value={NetworkInterfaces.VLAN}
									control={<Radio size="small" />}
									label={window.getCTTranslatedText(
										DisplayTextMap[NetworkInterfaces.VLAN]
									)}
									disabled={isFormDisabled}
								/>
							</RadioGroup>
						</Stack>
					</Stack>
				</FormControl>

				{networkInterface === NetworkInterfaces.LAN
					? renderLANForm()
					: renderVLANForm()}
			</Stack>
		</Box>
	);
}
