import { Stack, useTheme, IconButton, Tooltip } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import RefreshIcon from "@mui/icons-material/Refresh";

export interface ToolbarProps {
	show: boolean;
	hideToolbar: Function;
	children?: React.ReactNode;
	requestAPIRefresh?: () => void;
}

export default function GridToolbar({
	show,
	children,
	requestAPIRefresh,
}: ToolbarProps) {
	const theme = useTheme();

	if (!show) {
		return null;
	}
	const handleRefreshChange = () => {
		requestAPIRefresh?.();
	};
	return (
		<Toolbar
			id="grid-toolbar"
			variant="dense"
			sx={{
				zIndex: 2,
				padding: "4px !important",
				justifyContent: "flex-end",
				boxShadow: "unset !important",
			}}
		>
			<Stack direction="row" spacing={2} alignItems="center">
				{children}
				{requestAPIRefresh && (
					<Tooltip title="Refresh">
						<IconButton onClick={handleRefreshChange} color="primary">
							<RefreshIcon
								fontSize="small"
								sx={{ color: theme.palette.primary.main }}
							/>
						</IconButton>
					</Tooltip>
				)}
			</Stack>
		</Toolbar>
	);
}
