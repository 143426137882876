import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "modules/facets/types";
import { useMemo } from "react";
import { COMMON_FACETS } from "../../modules/facets/constants";

const AGENTS_FACET_ORDER: FacetGroupInfo = {
	...COMMON_FACETS,
	attacksurface: FacetGroups.Security,
	assetinboundstatus: FacetGroups.Security,
	blastradius: FacetGroups.Security,
	assetoutboundstatus: FacetGroups.Security,
	assetrisk: FacetGroups.Security,
	totalports: FacetGroups.Security,
	totalpaths: FacetGroups.Security,
	assetname: FacetGroups.Assets,
	internetoutboundenforcementready: FacetGroups.Assets,
	intranetoutboundenforcementready: FacetGroups.Assets,
	assetinternetfacing: FacetGroups.Assets,
	agentstatus: FacetGroups.Agents,
	currentversion: FacetGroups.Agents,
	policystatus: FacetGroups.Agents,
	macaddress: FacetGroups.Assets,
	addresses: FacetGroups.Assets,
	currenttrafficcfg: FacetGroups.Agents,
	currentdebuglogstate: FacetGroups.Agents,
	currentpolicytampermonitoringcfg: FacetGroups.Agents,
};

const NS_TRAFFIC_FACETS: FacetGroupInfo = {
	...AGENTS_FACET_ORDER,
	nstrafficstatus: FacetGroups.Agents,
};

const AGENTS_AND_ENDPOINTS_FACETS: FacetGroupInfo = {
	...AGENTS_FACET_ORDER,
	useremail: FacetGroups.Users,
	groupname: FacetGroups.Groups,
	idpname: FacetGroups.IdP,
};

export const useAgentsFacetsOrder = () => {
	const { isFeatureEnabled: isUserGroupFiltersFeatureEnabled } =
		useFeatureFlagControl(FEATURES.USER_GROUP_FILTERS);
	const { isFeatureEnabled: isNorthSouthTrafficEnabled } =
		useFeatureFlagControl(FEATURES.NS_TRAFFIC_CONFIG);

	const FACET_ORDER: FacetGroupInfo = useMemo(() => {
		if (isUserGroupFiltersFeatureEnabled && isNorthSouthTrafficEnabled) {
			return {
				...AGENTS_AND_ENDPOINTS_FACETS,
				...NS_TRAFFIC_FACETS,
			};
		}

		if (isUserGroupFiltersFeatureEnabled) {
			return AGENTS_AND_ENDPOINTS_FACETS;
		}

		if (isNorthSouthTrafficEnabled) {
			return NS_TRAFFIC_FACETS;
		}
		return AGENTS_FACET_ORDER;
	}, [isUserGroupFiltersFeatureEnabled, isNorthSouthTrafficEnabled]);

	return FACET_ORDER;
};
