import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { CTAlert } from "pages/monitoring/types";
import { useUpdateTotalCount } from "pages/paths/components/path-data-grid/hooks";
import { useMemo, useState } from "react";
import { AlertToolbar } from "../alert-data-grid-toolbar";
import { AlertDetails } from "./components";
import { ALERT_PINNED_COLUMNS, BASE_ALERT_COLUMNS } from "./constants";

export const ALERT_DATA_GRID_ID = "alert-data-grid";

export const AlertDataGrid = (props: DataGridProps<CTAlert>) => {
	const [selectedAlert, setSelectedAlert] = useState<CTAlert | undefined>();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	const selectedData: Array<CTAlert> | undefined = useMemo(() => {
		return props.rows?.filter((row: CTAlert) => {
			return selection.indexOf(row.alertHash) !== -1;
		});
	}, [selection, props.rows]);

	const selectedRawData: Array<CTAlert> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: CTAlert) => {
			return selection.indexOf(row.alertHash) !== -1;
		});
	}, [selection, props?.rawData]);

	const onClickClearSelection = () => {
		setSelection([]);
	};

	const columns = useMemo(() => {
		if (userPermissions.has("UPDATE_ALERT")) {
			return BASE_ALERT_COLUMNS;
		}

		return BASE_ALERT_COLUMNS.filter(col => col.field !== "action");
	}, [userPermissions]);

	useUpdateTotalCount({
		isLoading: props.isLoading,
		maxRowCount: props.maxRowCount,
		originalRowCount: props.originalRowCount,
		id: ALERT_DATA_GRID_ID,
	});

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<AlertToolbar
				hideToolbar={onClickClearSelection}
				show={true}
				selectedData={selectedData}
			/>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					rowHeight={64}
					defaultPinnedColumns={ALERT_PINNED_COLUMNS}
					columns={columns}
					pagination
					getRowId={({ alertHash }: CTAlert) => alertHash}
					exportRowCount={props.originalRowCount}
					paginationMode="server"
					sortingMode="server"
					{...props}
					onRowClick={params => {
						setSelectedAlert(params.row);
					}}
					isRowSelectable={data => data.row.status.toLowerCase() === "pending"}
					checkboxSelection={userPermissions.has("UPDATE_ALERT")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					selectedRawData={selectedRawData}
				/>
				{selectedAlert && (
					<AlertDetails
						alerts={selectedAlert}
						onClose={() => {
							setSelectedAlert(undefined);
						}}
					/>
				)}
			</Box>
		</Stack>
	);
};
