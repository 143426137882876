import { DataGridProProps } from "@mui/x-data-grid-pro";
import { CTDrawer } from "common/molecules/drawer";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { MatchedByTemplateType } from "modules/matched-by-templates/constants";
import { MatchedByTemplates, Path } from "pages/paths/types";
import { Port } from "pages/ports/types";
import {
	TemplateDetail,
	TemplateViewTab,
} from "pages/templates/components/template-detail/TemplateDetail";
import TemplateGroupTable from "pages/templates/components/template-detail/TemplateGroupTable";
import { TemplateViewDrawer } from "pages/templates/components/template-view-drawer";
import { TemplatePath, TemplatePort } from "pages/templates/types";
import { useCallback, useEffect, useState } from "react";
import { useMatchedByTemplatesStore } from "../hooks/useMatchedByTemplatesStore";
import { RuleEngineTemplate, RuleEngineTemplatePort } from "../types";
import { MemoizedTemplateDataFetcher } from "./MemoizedTemplateDataFetcher";
import { getActiveTab, matchedByTemplateRuleEngineDataMapper } from "./helpers";

interface MatchedByTemplateRuleEngineProps {
	isOpen: boolean;
	onClose: () => void;
	list: MatchedByTemplates[];
	winningTemplates: string[];
	pathRuleHashList: string[];
	portRuleHashList: string[];
	type: MatchedByTemplateType;
	port?: Port;
	path?: Path;
}

export const MatchedByTemplateRuleEngine = ({
	isOpen,
	onClose,
	list,
	pathRuleHashList,
	portRuleHashList,
	winningTemplates,
	type,
	port,
	path,
}: MatchedByTemplateRuleEngineProps) => {
	const [templateRefetchFunctions, setTemplateRefetchFunctions] = useState<
		Function[]
	>([]);
	const [ruleEngineTemplate, setRuleEngineTemplate] =
		useState<RuleEngineTemplate>({});
	const [isLoading, setIsLoading] = useState(false);
	const defaultTab = getActiveTab(ruleEngineTemplate);

	const { selectedTemplateId, setSelectedTemplateId } =
		useMatchedByTemplatesStore();

	const setTemplateRefetchFunctionsCallback = useCallback(
		(index: number, refetch: Function) => {
			setTemplateRefetchFunctions(prev => {
				const newList = [...prev];
				newList[index] = refetch;
				return newList;
			});
		},
		[]
	);

	useEffect(() => {
		if (isLoading || list.length === 0) {
			return;
		}

		if (
			templateRefetchFunctions.length === list.length &&
			Object.keys(ruleEngineTemplate).length === 0
		) {
			setIsLoading(true);

			Promise.all(templateRefetchFunctions.map(refetch => refetch()))
				.then(resolvedData => {
					const data = resolvedData.map(result => result.data);

					const ruleEngineTemplateData = matchedByTemplateRuleEngineDataMapper({
						data,
						portRuleHashList,
						pathRuleHashList,
						winningTemplates,
					});

					setRuleEngineTemplate(ruleEngineTemplateData);
					setIsLoading(false);
				})
				.catch(err => {
					console.error(err);
					setIsLoading(false);
				});
		}
	}, [
		isLoading,
		templateRefetchFunctions,
		ruleEngineTemplate,
		list.length,
		portRuleHashList,
		pathRuleHashList,
		winningTemplates,
	]);

	return (
		<>
			<CTDrawer
				title={"Status Summary"}
				open={isOpen}
				onClose={onClose}
				sx={{ height: "100%" }}
			>
				<>
					{Object.keys(ruleEngineTemplate).length === 0 &&
						list.map((template, index) => (
							<MemoizedTemplateDataFetcher
								key={template.templateId}
								templateId={template.templateId}
								setTemplateRefetchFunctions={
									setTemplateRefetchFunctionsCallback
								}
								index={index}
							/>
						))}

					<GroupedByPorts
						isLoading={isLoading}
						ruleEngineTemplate={ruleEngineTemplate}
						defaultTab={defaultTab}
						type={type}
						port={port}
						path={path}
					/>
				</>
			</CTDrawer>
			<TemplateViewDrawer
				isOpen={!!selectedTemplateId}
				onClose={() => setSelectedTemplateId("")}
				templateId={selectedTemplateId}
			/>
		</>
	);
};

interface GroupedByPortsProps {
	ruleEngineTemplate?: RuleEngineTemplate;
	isLoading: boolean;
	defaultTab?: TemplateViewTab;
	type: MatchedByTemplateType;
	port?: Port;
	path?: Path;
}

const GroupedByPorts = ({
	ruleEngineTemplate,
	isLoading,
	defaultTab,
	type,
	port,
	path,
}: GroupedByPortsProps) => {
	const portRows = ruleEngineTemplate?.templatePorts || [];
	const inboundPaths = ruleEngineTemplate?.inboundPaths || [];
	const outboundPaths = ruleEngineTemplate?.outboundPaths || [];

	const { isFeatureEnabled: isRuleEngineEnabled } = useFeatureFlagControl(
		FEATURES.TEMPLATE_EVAL_3
	);

	const getTemplateGroupTableRows = () => {
		if (Array.isArray(portRows) && portRows.length > 0) {
			const createGroupKey = (item: TemplatePort) => {
				return `${item.listenPortProtocol} ${item.listenPort}`;
			};

			const listenPortAsAny = portRows.filter(
				port => port.listenPort === "any"
			);
			const listenPortWithoutAny = portRows.filter(
				port => port.listenPort !== "any"
			);

			const hasOnlyAny = portRows.every(port => port.listenPort === "any");

			// Group items
			let grouped = listenPortWithoutAny.reduce(
				(acc, item) => {
					const key = createGroupKey(item);

					if (!acc[key]) {
						acc[key] = [];
					}

					acc[key].push(item);

					return acc;
				},
				{} as Record<string, TemplatePort[]>
			);

			if (hasOnlyAny) {
				grouped[
					port
						? `${port?.listenPortProtocol} ${port?.listenPort}`
						: `${path?.protocol} ${path?.port}`
				] = [];
			}

			const groupedWithAnyArray = Object.entries(grouped).map(
				([key, value]) => {
					return [key, [...value, ...listenPortAsAny]];
				}
			);

			const groupedWithAny: Record<string, TemplatePort[]> =
				Object.fromEntries(groupedWithAnyArray);

			const arr = Object.entries(groupedWithAny).map(([key, value]) => {
				const showWarning =
					new Set(value.map(item => item.listenPortReviewed)).size > 1;

				return {
					id: key,
					port: key,
					status: value.find((v: RuleEngineTemplatePort) => v.isWinningRule)
						?.listenPortReviewed,
					showWarning,
				};
			});

			return arr;
		} else if (Array.isArray(inboundPaths) && inboundPaths.length > 0) {
			const createGroupKey = (item: TemplatePath) => {
				return `${item.protocol} ${item.port}`;
			};

			const listenPortAsAny = inboundPaths.filter(port => port.port === "any");
			const listenPortWithoutAny = inboundPaths.filter(
				port => port.port !== "any"
			);

			const hasOnlyAny = inboundPaths.every(path => path.port === "any");

			// Group items
			let grouped = listenPortWithoutAny.reduce(
				(acc, item) => {
					const key = createGroupKey(item);

					if (!acc[key]) {
						acc[key] = [];
					}

					acc[key].push(item);

					return acc;
				},
				{} as Record<string, TemplatePath[]>
			);

			if (hasOnlyAny) {
				grouped[`${path?.protocol} ${path?.port}`] = [];
			}

			const groupedWithAnyArray = Object.entries(grouped).map(
				([key, value]) => {
					return [key, [...value, ...listenPortAsAny]];
				}
			);

			const groupedWithAny: Record<string, TemplatePort[]> =
				Object.fromEntries(groupedWithAnyArray);

			const arr = Object.entries(groupedWithAny).map(([key, value]) => {
				const showWarning =
					new Set(value.map(item => item.listenPortReviewed)).size > 1;

				return {
					id: key,
					port: key,
					status: value.find((v: RuleEngineTemplatePort) => v.isWinningRule)
						?.listenPortReviewed,
					showWarning,
				};
			});

			return arr;
		} else if (Array.isArray(outboundPaths) && outboundPaths.length > 0) {
			const createGroupKey = (item: TemplatePath) => {
				return `${item.protocol} ${item.port}`;
			};

			const listenPortAsAny = outboundPaths.filter(port => port.port === "any");
			const listenPortWithoutAny = outboundPaths.filter(
				port => port.port !== "any"
			);

			// Group items
			let grouped = listenPortWithoutAny.reduce(
				(acc, item) => {
					const key = createGroupKey(item);

					if (!acc[key]) {
						acc[key] = [];
					}

					acc[key].push(item);

					return acc;
				},
				{} as Record<string, TemplatePath[]>
			);
			const hasOnlyAny = outboundPaths.every(port => port.port === "any");

			if (hasOnlyAny) {
				grouped[`${path?.protocol} ${path?.port}`] = [];
			}

			const groupedWithAnyArray = Object.entries(grouped).map(
				([key, value]) => {
					return [key, [...value, ...listenPortAsAny]];
				}
			);

			const groupedWithAny: Record<string, TemplatePort[]> =
				Object.fromEntries(groupedWithAnyArray);

			const arr = Object.entries(groupedWithAny).map(([key, value]) => {
				const showWarning =
					new Set(value.map(item => item.listenPortReviewed)).size > 1;

				return {
					id: key,
					port: key,
					status: value.find((v: RuleEngineTemplatePort) => v.isWinningRule)
						?.listenPortReviewed,
					showWarning,
				};
			});

			return arr;
		}
		return [];
	};

	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps["getDetailPanelContent"]>
	>(() => {
		return (
			<>
				<TemplateDetail
					templateId={undefined}
					viewOnly
					isEmbeddedInDataGrid
					hideMetadata
					isLoading={isLoading}
					ruleEngineTemplate={ruleEngineTemplate}
					defaultTab={defaultTab}
					columnMode="groupedByRule"
					type={type}
					isRuleEngineEnabled={isRuleEngineEnabled}
				/>
			</>
		);
	}, [defaultTab, isLoading, isRuleEngineEnabled, ruleEngineTemplate, type]);

	return (
		<TemplateGroupTable
			rows={getTemplateGroupTableRows()}
			getDetailPanelContent={getDetailPanelContent}
		/>
	);
};
