import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	Drawer,
	DrawerProps,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";

export interface CTDrawerProps extends DrawerProps {
	open: boolean;
	actions?: React.ReactNode;
	onClose: VoidFunction;
	title: string;
	secondaryBtnText?: string;
	drawerWidth?: string;
	subTitle?: string;
}

export function CTDrawer({ drawerWidth, ...props }: CTDrawerProps) {
	const title = window.getCTTranslatedText(props.title ?? "");

	return (
		<Drawer
			anchor="right"
			PaperProps={{
				sx: {
					p: 0,
					width: drawerWidth ?? "70%",
					maxwidth: "1000px",
					minWidth: "600px",
				},
				elevation: 1,
			}}
			{...props}
			title=""
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%", pb: 3 }}
			>
				<Stack direction="row" spacing={2} sx={{ mt: 5, mx: 4 }}>
					<Typography variant="h5">
						<b>{window.getCTTranslatedText(title)}</b>
						{window.getCTTranslatedText(props.subTitle ?? "")}
					</Typography>
				</Stack>

				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={() => props.onClose()}
						sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>

			<DialogContent
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					overflow: "auto",
					pt: 0,
				}}
			>
				<Box
					pt={2}
					sx={{
						width: "100%",
						flex: 1,
					}}
				>
					{props.children}
				</Box>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={() => props.onClose()}>
					{window.getCTTranslatedText(props.secondaryBtnText ?? "Close")}
				</Button>
				{props.actions}
			</DialogActions>
		</Drawer>
	);
}
