import { AIXLogo, AppleIcon, LinuxIcon, WindowsIcon } from "assets/svgs";
import { ContainerIcon } from "assets/svgs/ContainerIcon";
import { DebianIcon } from "assets/svgs/DebianIcon";
import { HelmIcon } from "assets/svgs/HelmIcon";
import { IstioIcon } from "assets/svgs/IstioIcon";
import { KubernetesIcon } from "assets/svgs/KubernetesIcon";
import { SolarisLogo } from "assets/svgs/SolarisLogo";
import { SuseIcon } from "assets/svgs/SuseIcon";
import { PLATFORMS, environments } from "../constants";
export function renderProcessPlatformIcon(platform: string, color: string) {
	switch (platform) {
		case PLATFORMS.RPM:
			return <LinuxIcon width="30" height="30" color={color} />;
		case PLATFORMS.DEBIAN:
			return <DebianIcon width="35" height="35" color={color} />;
		case PLATFORMS.WINDOWS:
			return <WindowsIcon width="30" height="30" color={color} />;
		case PLATFORMS.DARWIN:
			return <AppleIcon width="35" height="35" color={color} />;
		case PLATFORMS.DOCKER:
			return <ContainerIcon width="30" height="30" color={color} />;
		case PLATFORMS.AIX:
			return <AIXLogo width="30" height="30" color={color} />;
		case PLATFORMS.SUSE:
			return <SuseIcon width="35" height="35" color={color} />;
		case PLATFORMS.SOLARIS:
			return <SolarisLogo width="35" height="35" color={color} />;
		case environments.KUBERNETES:
			return <KubernetesIcon color={color} />;
		case environments.ISTIO:
			return <IstioIcon color={color} />;
		case environments.HELM:
			return <HelmIcon color={color} />;

		default:
			return <LinuxIcon width="30" height="30" color={color} />;
	}
}
