import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { Template, TemplateFormProps } from "pages/templates/types";
import { TemplateForm } from "./components/template-form";
import { TemplateFormMode } from "./components/template-form/types";

interface TemplateFormDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	template?: Template | TemplateFormProps;
	updateTemplateData: Function;
	title?: string;
	mode: TemplateFormMode;
	btnTitle?: string;
	backButtonEnable?: boolean;
	actionType?: TemplateActionType;
}

export const TemplateFormDrawer = ({
	isOpen,
	onClose,
	template,
	title,
	updateTemplateData,
	mode = TemplateFormMode.CREATE,
	btnTitle = "create",
	backButtonEnable,
	actionType,
}: TemplateFormDrawerProps) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					p: 0,
					width: "70%",
					maxwidth: "1000px",
					minWidth: "600px",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "20px", top: "20px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<TemplateForm
				title={title ? title : `Create New Template`}
				updateTemplateData={updateTemplateData}
				onClose={onClose}
				template={template}
				mode={mode}
				btnTitle={btnTitle}
				backButtonEnable={backButtonEnable}
				actionType={actionType}
			/>
		</Drawer>
	);
};
