import {
	FormControlLabel,
	Radio,
	RadioGroup,
	SelectChangeEvent,
} from "@mui/material";
import { AGENT_TYPES, AGENT_TYPES_RADIO_BUTTON, PLATFORMS } from "./constants";

interface PlatformArchitecturesRadioGroupProps {
	platform: PLATFORMS;
	selectedOption: AGENT_TYPES;
	setSelectedOption: (value: AGENT_TYPES) => void;
}

export const PlatformArchitecturesRadioGroup = ({
	platform,
	selectedOption,
	setSelectedOption,
}: PlatformArchitecturesRadioGroupProps) => {
	function setSelectedValue(event: SelectChangeEvent) {
		switch (event?.target?.value) {
			case AGENT_TYPES.CT_AGENT:
				setSelectedOption(AGENT_TYPES.CT_AGENT);
				break;
			case AGENT_TYPES.CT_LEGACY_AGENT:
				setSelectedOption(AGENT_TYPES.CT_LEGACY_AGENT);
				break;
			case AGENT_TYPES.CT_USER_AGENT:
				setSelectedOption(AGENT_TYPES.CT_USER_AGENT);
				break;
			case AGENT_TYPES.CT_CONTAINER_AGENT:
				setSelectedOption(AGENT_TYPES.CT_CONTAINER_AGENT);
				break;
		}
	}

	return (
		<RadioGroup
			sx={{ minWidth: 150 }}
			aria-label="agent-type"
			color="info"
			name="agent-type"
			value={selectedOption}
			onChange={setSelectedValue}
		>
			{Object.keys(AGENT_TYPES).map((option: any, index: any) => {
				return renderArchitectureOption(option, index, platform);
			})}
		</RadioGroup>
	);
};

function renderArchitectureOption(
	option: string,
	index: number,
	platform: PLATFORMS
) {
	const isCTAgent = option === AGENT_TYPES.CT_AGENT;
	const isCTUserAgent = option === AGENT_TYPES.CT_USER_AGENT;
	const isCTContainerAgent = option === AGENT_TYPES.CT_CONTAINER_AGENT;
	const isCTLegacyAgent = option === AGENT_TYPES.CT_LEGACY_AGENT;
	const isAix = platform === PLATFORMS.AIX;
	const isSolaris = platform === PLATFORMS.SOLARIS;
	const isRpmLegacy = platform === PLATFORMS.RPM_LEGACY;
	const isDebianOrRpmAixSolaris =
		platform === PLATFORMS.DEBIAN ||
		platform === PLATFORMS.RPM ||
		platform === PLATFORMS.AIX ||
		platform === PLATFORMS.RPM_LEGACY ||
		platform === PLATFORMS.SUSE ||
		platform === PLATFORMS.SOLARIS;

	const isDocker = platform === PLATFORMS.DOCKER;

	const shouldRender =
		(isCTContainerAgent && isDocker) ||
		(isCTAgent && !isAix && !isRpmLegacy && !isSolaris) ||
		(!isDebianOrRpmAixSolaris && isCTUserAgent) ||
		((isAix || isRpmLegacy || isSolaris) && isCTLegacyAgent);

	if (isDocker && !isCTContainerAgent) {
		return null;
	}
	if (!shouldRender) {
		return null;
	}
	const label =
		AGENT_TYPES_RADIO_BUTTON[option as keyof typeof AGENT_TYPES_RADIO_BUTTON];
	return (
		<FormControlLabel
			key={index}
			value={option}
			control={<Radio size="small" color="default" />}
			label={window.getCTTranslatedText(label)}
		/>
	);
}
