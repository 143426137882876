import { useMutation } from "@tanstack/react-query";
import { MatchedByTemplateType } from "modules/matched-by-templates/constants";

interface PathRuleMatchesAPIProps {
	assetId?: string;
	id?: string;
	type: MatchedByTemplateType;
}

export function usePathRuleMatchesAPI({
	type,
	assetId,
	id,
}: PathRuleMatchesAPIProps) {
	const path = `assets/${assetId}/${type === MatchedByTemplateType.PATH ? "paths" : "openports"}/${id}/rule-matches`;

	return useMutation<any, Error, any>(["rule-matches", path, "post"], {});
}
