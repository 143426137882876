import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useUpdateAssetAnnotations } from "pages/asset/components/asset-detail/hooks/use-update-asset-annotations";
import { NO_VALUE } from "pages/asset/constants";
import { useAssetStore } from "pages/assets/store";
import {
	Asset,
	AssetType,
	CoreTagsKeys,
	CoreTagsLabels,
} from "pages/assets/types";
import { useTagsAPI } from "pages/tags/hooks";
import pluralize from "pluralize";
import MetadataEdit from "./MetadataEdit";
import { FormBuilderElement, FormBuilderElementType } from "./types";

interface Props {
	asset?: Asset;
	open: boolean;
	handleAllowEdit: (bool: boolean) => void;
	updateAssetMetadata: Function;
	mode?: "single" | "bulk";
	assetIds?: Array<Asset["assetId"]>;
}

function AssetEdit({
	asset,
	assetIds = [],
	mode = "single",
	open,
	handleAllowEdit,
	updateAssetMetadata,
}: Props) {
	const { tagFields } = useTagsAPI();
	const { mutate, isLoading: isEditSubmitLoading } = useUpdateAssetAnnotations(
		asset?.assetId,
		mode
	);
	const requestAssetAPIRefresh = useAssetStore(
		state => state.requestAPIRefresh
	);
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const getPluralText = (text: string) => {
		return window.getCTTranslatedText(text, {
			mode: window.getCTTranslatedText(
				pluralize("Asset", mode === "single" ? 1 : 2)
			),
		});
	};

	const title = getPluralText("AssetEditDetailsTitle");
	const infoText = getPluralText("AssetEditDetailsInfo");

	const generateFormElements: () => FormBuilderElement[] = () => {
		const result: FormBuilderElement[] = [];

		if (mode === "single") {
			result.push({
				key: "assetName",
				label: "Name",
				value: asset?.assetName ?? "",
				columns: 12,
				type: FormBuilderElementType.Text,
			});
		}

		type Tag = FormBuilderElement & { isUserDefinedTag?: boolean };

		const tags: Tag[] = [
			{
				key: "businessValue",
				label: "Business Value",
				value: asset?.businessValue ?? "",
				columns: 6,
				type: FormBuilderElementType.BusinessValue,
			},
		];

		if (asset?.type === AssetType.Device) {
			tags.push({
				key: "vendorInfo",
				label: "Vendor Info",
				value: asset?.vendorInfo ?? "",
				columns: 6,
				type: FormBuilderElementType.Text,
			});
		}

		Object.entries(asset?.coreTags ?? {}).forEach(([key, value]) => {
			// Using . notation to indicate to RHF that this is an entry in the coreTags object
			const rhfKey = `coreTags.${key}`;

			// If value is falsy, ignore
			if (!value) {
				return;
			}

			tags.push({
				key: rhfKey,
				label: window.getCTTranslatedText(key),
				value: value ?? "",
				columns: 6,
				type: FormBuilderElementType.Tag,
			});
		});

		tagFields?.coreTags.forEach(tag => {
			const DEFAULT_TAGS: Array<string> = [
				CoreTagsKeys.Application,
				CoreTagsKeys.Environment,
				CoreTagsKeys.Location,
				CoreTagsKeys.Owner,
				CoreTagsKeys.Role,
			];

			const DEVICE_TAGS: Array<string> = [
				...DEFAULT_TAGS,
				CoreTagsKeys.Model,
				CoreTagsKeys.Category,
				CoreTagsKeys.SubCategory,
				CoreTagsKeys.Manufacturer,
				CoreTagsKeys.KernelVersion,
				CoreTagsKeys.SerialNumber,
			];

			const key = tag.name ?? "";
			// Using . notation to indicate to RHF that this is an entry in the coreTags object
			const rhfKey = `coreTags.${key}`;

			if (asset?.type === AssetType.Device && DEVICE_TAGS.includes(key)) {
				tags.push({
					key: rhfKey,
					label: CoreTagsLabels[key as CoreTagsKeys],
					value: asset?.coreTags[key] ?? "",
					columns: 6,
					type: FormBuilderElementType.Tag,
				});
			} else if (DEFAULT_TAGS.includes(key)) {
				tags.push({
					key: rhfKey,
					label: CoreTagsLabels[key as CoreTagsKeys],
					value: asset?.coreTags[key] ?? "",
					columns: 6,
					type: FormBuilderElementType.Tag,
				});
			}
		});

		tagFields?.userDefinedTags.forEach(tag => {
			const key = tag.displayName ?? "";
			// Using . notation to indicate to RHF that this is an entry in the coreTags object
			const rhfKey = `coreTags.${key}`;

			const label = tag.displayName;
			const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);

			tags.push({
				key: rhfKey,
				label: capitalizedLabel,
				value: asset?.coreTags[key] ?? "",
				columns: 6,
				type: FormBuilderElementType.Tag,
				isUserDefinedTag: true,
			});
		});

		const uniqueTags = Array.from(
			new Map(tags.map(ele => [ele.key, ele])).values()
		);

		const sortedTags = uniqueTags.sort((a, b) => {
			if (a.isUserDefinedTag === b.isUserDefinedTag) {
				return a.label.localeCompare(b.label);
			}
			return a.isUserDefinedTag ? 1 : -1;
		});

		const sortedTagsWithoutIsUserDefinedTag = sortedTags.map(
			({ isUserDefinedTag, ...obj }) => {
				return obj;
			}
		);

		return [...result, ...sortedTagsWithoutIsUserDefinedTag];
	};

	const onEditSubmit = (data: Partial<Asset>) => {
		const handleNoValueString = (body: Partial<Asset>) => {
			const obj = { ...body };
			for (const key in obj?.coreTags) {
				if (obj.coreTags[key] === NO_VALUE) {
					obj.coreTags[key] = "";
				}
			}

			if (!obj.businessValue) {
				delete obj.businessValue;
			}

			return obj;
		};

		const body =
			mode === "single"
				? handleNoValueString(data)
				: {
						criteria: `assetId in (${(assetIds ?? [])
							.map(id => `'${id}'`)
							.join(",")})`,
						...handleNoValueString(data),
					};

		mutate(body, {
			onSuccess: () => {
				updateAssetMetadata({
					body: body,
					id: "assetName",
				});
				requestAssetAPIRefresh();

				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "AssetDetailsSubmittedSuccessfully",
				});
				setTimeout(() => {
					handleAllowEdit(false);
				}, 500);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	return (
		<MetadataEdit
			title={title}
			infoText={infoText}
			data={generateFormElements()}
			open={open}
			onClose={() => handleAllowEdit(false)}
			isSubmitLoading={isEditSubmitLoading}
			onSubmit={onEditSubmit}
		/>
	);
}

export default AssetEdit;
