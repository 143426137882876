import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { useDemoController } from "hooks/useDemoController";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useMemo, useState } from "react";
import { DEVICE_COLUMNS } from "./constants";
import { UnmanagedDevice } from "../appliance-unmanaged-devices/types";
import { UnmanagedDeviceToolbar } from "./components/UnmanagedDeviceToolbar";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useApplianceConfigGetAPI } from "pages/appliances/components/appliance-form-drawer/hooks";
import { BooleanFlags } from "pages/appliances/components/appliance-form-drawer/components/appliance-config-form/types";

export const UnmanagedDevicesDataGrid = (
	props: DataGridProps<UnmanagedDevice>
) => {
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const deviceColumns: GridColDef[] = DEVICE_COLUMNS(props.viewOnly ?? false);
	const { isDemo, handleDemoClick } = useDemoController();

	const onClickClearSelection = () => {
		setSelection([]);
	};

	const selectedData: Array<UnmanagedDevice> | undefined = useMemo(() => {
		return props.rows?.filter((row: UnmanagedDevice) => {
			return selection.indexOf(row.deviceId) !== -1;
		});
	}, [selection, props.rows]);

	const selectedRawData: Array<UnmanagedDevice> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: UnmanagedDevice) => {
			return selection.indexOf(row.deviceId) !== -1;
		});
	}, [selection, props?.rawData]);

	const { data: config } = useApplianceConfigGetAPI(props.applianceId);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			{selection && selection?.length > 0 && (
				<UnmanagedDeviceToolbar
					selectedData={selectedData ?? []}
					hideToolbar={onClickClearSelection}
					show={selection?.length > 0}
					disableActions={
						config?.gatekeeperConfig?.dhcp?.optInSupport === BooleanFlags.False
					}
				/>
			)}
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					slots={{
						noRowsOverlay: () =>
							DevicesNoRowsOverlayComp({ isDemo, handleDemoClick, props }),
					}}
					checkboxSelection={userPermissions.has("UPDATE_UNMANAGED_TO_MANAGED")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					rowHeight={64}
					columns={deviceColumns}
					pagination
					getRowId={({ deviceId }: UnmanagedDevice) => deviceId}
					paginationMode="server"
					sortingMode="server"
					{...props}
					selectedRawData={selectedRawData}
				/>
			</Box>
		</Stack>
	);
};

interface DevicesNoRowsOverlayCompProps {
	isDemo?: boolean;
	handleDemoClick: () => Promise<void>;
	props: DataGridProps<UnmanagedDevice>;
}

const DevicesNoRowsOverlayComp = ({
	isDemo,
	handleDemoClick,
	props,
}: DevicesNoRowsOverlayCompProps) => {
	return (
		<Stack
			alignItems={"center"}
			justifyContent="center"
			sx={{
				zIndex: 100,
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			{isDemo && <CircularProgress sx={{ mb: 2 }} size={36} />}
			<Typography variant="body2">
				{window.getCTTranslatedText(
					isDemo ? "Preparing demo data" : "No results"
				)}
			</Typography>
			{!isDemo && props.hasNoData && (
				<Button onClick={handleDemoClick}>
					{window.getCTTranslatedText("Try demo data")}
				</Button>
			)}
		</Stack>
	);
};
