import { Box, Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { Network } from "pages/networks/types";
import { useMemo, useState } from "react";
import {
	ENDPOINT_NETWORKS_COLUMNS,
	NETWORKS_COLUMNS,
	NetworkColumnConfig,
} from "./constants";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { NetworkToolBar } from "../network-datagrid-tool-bar";
import { GridRowId } from "@mui/x-data-grid-pro";

export const NetworkDataGrid = (props: DataGridProps<Network>) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const { isFeatureEnabled: isUserGroupsColumnEnabled } = useFeatureFlagControl(
		FEATURES.USER_GROUP_COLUMN
	);
	const networkColumns = isUserGroupsColumnEnabled
		? ENDPOINT_NETWORKS_COLUMNS
		: NETWORKS_COLUMNS;
	const columns = useMemo(() => {
		let columns = userPermissions.has("DELETE_NAMED_NETWORK")
			? networkColumns
			: networkColumns.filter(col => col.field !== "action");
		return columns;
	}, [userPermissions, networkColumns]);
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const selectedData: Array<Network> | undefined = useMemo(() => {
		return props.rows?.filter((row: Network) => {
			return selection.indexOf(row?.namedNetworkId) !== -1;
		});
	}, [selection, props.rows]);
	const onClickClearSelection = () => {
		setSelection([]);
	};
	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<NetworkToolBar
				show={true}
				hideToolbar={onClickClearSelection}
				selection={selectedData}
			/>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					defaultPinnedColumns={NetworkColumnConfig.PinnedColumns}
					rowHeight={64}
					columns={columns}
					pagination
					getRowId={({ namedNetworkId }: Network) => namedNetworkId}
					paginationMode="server"
					sortingMode="server"
					{...props}
				/>
			</Box>
		</Stack>
	);
};
