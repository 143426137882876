import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import ForkLeftOutlined from "@mui/icons-material/ForkLeftOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import HiveOutlinedIcon from "@mui/icons-material/HiveOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import ClusterOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import WifiTetheringOutlinedIcon from "@mui/icons-material/WifiTetheringOutlined";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";

export interface Route {
	pathname: string;
	title?: string;
	sidebar?: {
		icon: JSX.Element | null;
		bottom?: boolean;
		isCustomIcon?: boolean;
	};
	queryParams?: string;
	redirect?: string;
	displayTitle?: boolean;
	secondaryBreadcrumbTitle?: string;
}

interface Routes {
	[key: string]: Route;
}

export const ROUTES: Routes = {
	ALL: {
		pathname: "*",
	},
	DASHBOARD: {
		pathname: "/dashboard",
		title: "Dashboard",
		sidebar: {
			icon: <SpeedOutlinedIcon fontSize="medium" />,
		},
	},
	AUTH_LOGIN: {
		pathname: "/auth/login",
		title: "Login",
	},
	AUTH_INVITE: {
		pathname: "/auth/invite",
		title: "Invite",
	},
	AUTH_SIGNUP: {
		pathname: "/auth/signup",
		title: "Signup",
	},
	[FEATURES.PANOPTIC_MAP]: {
		pathname: "/visualizer",
		title: "Panoptic Map",
		sidebar: {
			icon: <VisibilityOutlinedIcon fontSize="medium" />,
		},
	},
	ASSETS: {
		pathname: "/assets",
		title: "Assets",
		sidebar: {
			icon: <ListAltOutlinedIcon fontSize="medium" />,
		},
	},
	ASSET: {
		pathname: "/assets/:assetId",
		title: "Asset",
	},
	USER_SEGMENTATION: {
		pathname: "/users",
		title: "Users",
		sidebar: {
			icon: <GroupOutlinedIcon fontSize="medium" />,
			bottom: true,
		},
	},
	TAGS: {
		pathname: "/tags",
		title: "Tags",
		sidebar: {
			icon: <LocalOfferOutlinedIcon fontSize="medium" />,
		},
	},
	TAG: {
		pathname: "/tags/:tag",
		title: "Tag",
	},
	SEGMENT: {
		pathname: "/segment",
		title: "Segments",
		sidebar: {
			icon: <HiveOutlinedIcon fontSize="medium" />,
		},
	},
	PORTS: {
		pathname: "/ports",
		title: "Ports",
		sidebar: {
			icon: null,
			isCustomIcon: true,
		},
	},
	PORT: {
		pathname: "/port",
		title: "Port",
	},
	PATHS: {
		pathname: "/paths",
		title: "Paths",
		sidebar: {
			icon: <ForkLeftOutlined fontSize="medium" />,
		},
	},
	PATH: {
		pathname: "/path",
		title: "Path",
	},
	TEMPLATES: {
		pathname: "/templates",
		title: "Templates",
		sidebar: {
			icon: <DocumentScannerOutlinedIcon fontSize="medium" />,
			isCustomIcon: true,
		},
	},
	TEMPLATE: {
		pathname: "/template/:templateId",
		title: "Template",
	},

	NETWORKS: {
		pathname: "/networks",
		title: "Networks",
		sidebar: {
			icon: <DeviceHubOutlinedIcon fontSize="medium" />,
		},
	},
	NETWORK: {
		pathname: "/network/:networkId",
		title: "Network",
	},

	CONNECTORDOWNLOAD: {
		pathname: "/connectors/download",
		title: "Download",
	},
	HOST_GROUPS: {
		pathname: "/host-groups",
		title: "Host Groups",
		sidebar: {
			icon: <DnsOutlinedIcon fontSize="medium" />,
			bottom: true,
		},
	},
	AGENTS: {
		pathname: "/agents",
		title: "Agents",
		sidebar: {
			icon: <WifiTetheringOutlinedIcon fontSize="medium" />,
			bottom: true,
		},
	},
	APPLIANCES: {
		pathname: "/appliances",
		title: "Gatekeepers",
		sidebar: {
			icon: <RouterOutlinedIcon fontSize="medium" />,
			bottom: true,
		},
	},
	APPLIANCE: {
		pathname: "/appliances/:applianceName",
		title: "Gatekeeper",
	},
	DEVICES: {
		pathname: "/devices/:assetId",
		title: "Gatekeepers",
		secondaryBreadcrumbTitle: "Devices",
	},

	CLUSTERS: {
		pathname: "/containers",
		title: "Containers",
		sidebar: {
			icon: <ClusterOutlinedIcon fontSize="medium" />,
			bottom: true,
		},
	},
	AGENT: {
		pathname: "/agents/:assetId",
		title: "Asset",
	},
	MONITORING: {
		pathname: "/monitoring",
		title: "Monitoring",
		sidebar: {
			icon: <MonitorHeartOutlinedIcon fontSize="medium" />,
		},
	},
	SETTINGS: {
		pathname: "/settings",
		title: "Settings",
		sidebar: {
			icon: <SettingsOutlinedIcon fontSize="medium" />,
			bottom: true,
		},
	},
	PROFILE: {
		pathname: "/profile",
		title: "Profile",
	},
	ORG: {
		pathname: "/organization",
		title: "Organization",
	},
	INTEGRATIONS: {
		pathname: "/integrations",
		title: "Integrations",
	},
	USERS: {
		pathname: "/integrations/users",
		title: "Users",
		displayTitle: true,
	},
	GROUPS: {
		pathname: "/integrations/groups",
		title: "User Groups",
		displayTitle: true,
	},
	DEPARTMENTS: {
		pathname: "/integrations/departments",
		title: "Departments",
		displayTitle: true,
	},
	API_KEYS: {
		pathname: "/api-keys",
		title: "Manage APIs",
	},
	INSTALL_AGENTS: {
		pathname: "/agents/install-agents",
		title: "Install Agent",
	},
	REPORTS: {
		pathname: "/reports",
		title: "Reports",
	},
	INSTALL_APPLIANCES: {
		pathname: "/appliances/install-appliances",
		title: "installGatekeeper",
	},
	INSTALL_COLLECTOR: {
		pathname: "/containers/install-agent",
		title: "Install Agent",
	},
	VISUALIZER: {
		pathname: "/visualizer",
		title: "Traffic Visualizer",
	},
	TAG_RULES: {
		pathname: "/tag-rules",
		title: "tagLabelRules",
		redirect: "/tags",
	},
	TAG_RULE: {
		pathname: "/tag-rules/create",
		title: "Tag Label Rule",
		redirect: "/tags",
	},
	EDIT_RULE: {
		pathname: "/tag-rules/edit-rule/:ruleId",
		title: "Edit",
	},
	SEGMENT_CREATION: {
		pathname: "/segment/create",
		title: "segments",
		redirect: "/segment",
	},
	EDIT_POLICY: {
		pathname: "/tag-policy/edit-policy/:tagBasedPolicyId",
		title: "Edit",
	},
	SEGMENT_EDIT: {
		pathname: "/segment/edit/:tagBasedPolicyId",
		title: "Edit",
	},
	CLONE_POLICY: {
		pathname: "/tag-policy/clone-policy/:tagBasedPolicyId",
		title: "Clone",
	},
	SEGMENT_CLONE: {
		pathname: "/segment/clone/:tagBasedPolicyId",
		title: "Clone",
	},
};
export const SECONDARY_TITLE_TO_PATH_MAP: Record<string, string> = {
	APPLIANCES: "devices",
};
