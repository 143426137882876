import {
	FEATURES,
	useFeatureFlagControl,
} from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { FacetGroupInfo } from "modules/core/types";
import { COMMON_FACETS } from "modules/facets/constants";
import { FacetGroups } from "modules/facets/types";
import { FACET_ORDER as TEMPLATE_FACET_ORDER } from "pages/templates/constants";
import { useMemo } from "react";
import { AssetStatus } from "./types";

const ASSETS_FACET_ORDER: FacetGroupInfo = {
	...COMMON_FACETS,
	attacksurface: FacetGroups.Security,
	pendingattacksurfacechanges: FacetGroups.Security,
	assetinboundstatus: FacetGroups.Security,
	newpathprocessingstopped: FacetGroups.Assets,
	blastradius: FacetGroups.Security,
	pendingblastradiuschanges: FacetGroups.Security,
	assetoutboundstatus: FacetGroups.Security,
	assetrisk: FacetGroups.Security,
	totalports: FacetGroups.Security,
	totalpaths: FacetGroups.Security,
	assetname: FacetGroups.Assets,
	internetoutboundenforcementready: FacetGroups.Assets,
	intranetoutboundenforcementready: FacetGroups.Assets,
	assetinternetfacing: FacetGroups.Assets,
	untagged: FacetGroups.Assets,
	agentstatus: FacetGroups.Agents,
	currentversion: FacetGroups.Agents,
	policystatus: FacetGroups.Agents,
	agenttype: FacetGroups.Agents,
	namednetworkname: FacetGroups.Network,
	provider: FacetGroups.Network,
	isoobnetwork: FacetGroups.Network,
	vulnerabilities: FacetGroups.Security,
	securitypatches: FacetGroups.Security,
	cveid: FacetGroups.Security,
	package: FacetGroups.Security,
	lowestassetinboundstatus: FacetGroups.PolicyAutomation,
	lowestassetoutboundstatus: FacetGroups.PolicyAutomation,
	macaddress: FacetGroups.Assets,
	addresses: FacetGroups.Assets,
	autosynchronize: FacetGroups.PolicyAutomation,
	...TEMPLATE_FACET_ORDER,
};

const ENDPOINTS_FACET_ORDER: FacetGroupInfo = {
	signedin: FacetGroups.Users,
	useremail: FacetGroups.Users,
	groupname: FacetGroups.Groups,
	idpname: FacetGroups.IdP,
};

const DEVICES_FACET_ORDER: FacetGroupInfo = {
	agentname: FacetGroups.Gatekeepers,
	agentlocation: FacetGroups.Gatekeepers,
	assetavailability: FacetGroups.Gatekeepers,
};

const ASSETS_AND_ENDPOINTS_FACETS: FacetGroupInfo = {
	...ASSETS_FACET_ORDER,
	...ENDPOINTS_FACET_ORDER,
};

const ASSETS_AND_DEVICES_FACETS: FacetGroupInfo = {
	...ASSETS_FACET_ORDER,
	...DEVICES_FACET_ORDER,
};

const ALL_FACETS: FacetGroupInfo = {
	...ASSETS_FACET_ORDER,
	...ENDPOINTS_FACET_ORDER,
	...DEVICES_FACET_ORDER,
};

// isBeta
export const FACET_ORDER_BETA: FacetGroupInfo = {};

export const useAssetsFacetsOrder = () => {
	const { isFeatureEnabled: isUserGroupFiltersFeatureEnabled } =
		useFeatureFlagControl(FEATURES.USER_GROUP_FILTERS);
	const { isFeatureEnabled: isDevicesFiltersFeatureEnabled } =
		useFeatureFlagControl(FEATURES.DEVICES_FILTERS);
	const { isFeatureEnabled: isExperimentalAssetsFeatureEnabled } =
		useFeatureFlagControl(FEATURES.EXPERIMENTAL_ASSETS_FACETS);

	const FACET_ORDER: FacetGroupInfo = useMemo(() => {
		let baseFacetOrder;
		if (!isUserGroupFiltersFeatureEnabled && !isDevicesFiltersFeatureEnabled) {
			baseFacetOrder = ASSETS_FACET_ORDER;
		} else if (
			isUserGroupFiltersFeatureEnabled &&
			isDevicesFiltersFeatureEnabled
		) {
			baseFacetOrder = ALL_FACETS;
		} else if (isDevicesFiltersFeatureEnabled) {
			baseFacetOrder = ASSETS_AND_DEVICES_FACETS;
		} else {
			baseFacetOrder = ASSETS_AND_ENDPOINTS_FACETS;
		}

		if (isExperimentalAssetsFeatureEnabled) {
			return {
				...baseFacetOrder,
				...FACET_ORDER_BETA,
			};
		}
		return baseFacetOrder;
	}, [
		isUserGroupFiltersFeatureEnabled,
		isDevicesFiltersFeatureEnabled,
		isExperimentalAssetsFeatureEnabled,
	]);

	return FACET_ORDER;
};

// isAlpha
export const useFacetOrderAlpha = () => {
	const assetsFacetsOrder = useAssetsFacetsOrder();

	const FACET_ORDER_ALPHA = useMemo(() => {
		return {
			...assetsFacetsOrder,
			lowestassetprogressiveinboundstatus: FacetGroups.PolicyAutomation, // isProgressiveEnabled
		};
	}, [assetsFacetsOrder]);

	return FACET_ORDER_ALPHA;
};

export const AttackSurfaceStatusOptionsText: { [key: string]: string } = {
	[AssetStatus.Unsecured]: "Unsecure",
	[AssetStatus.SimulateSecureInternet]: "Simulate Secure Internet",
	[AssetStatus.SecureInternet]: "Secure Internet",
	[AssetStatus.SimulateSecureAll]: "Simulate Secure All",
	[AssetStatus.SecureAll]: "Secure All",
};

export const BlastRadiusStatusOptionsText: { [key: string]: string } = {
	[AssetStatus.Unsecured]: "Unsecure",
	[AssetStatus.SimulateSecureInternet]: "Simulate Secure Internet",
	[AssetStatus.SecureInternet]: "Secure Internet",
	[AssetStatus.SimulateSecureAll]: "Simulate Secure All",
	[AssetStatus.SecureAll]: "Secure All",
};

export enum AssetFirewallStatus {
	Synchronize = "Push to Firewall",
}
