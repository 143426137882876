import CancelIcon from "@mui/icons-material/Cancel";
import {
	Box,
	Chip,
	Menu,
	Stack,
	Tooltip,
	alpha,
	useTheme,
} from "@mui/material";
import {
	getSelectedTime,
	getSelectedTimeFacetNameFromScope,
} from "common/atoms/facet-open-close-controller/helpers";
import { TimeFilter } from "common/molecules/TimeFilter";
import { useCommonStore } from "common/store";
import { updateTimeFilterFacet } from "common/store/helper";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { CustomCommonStoreType } from "common/types/types";
import { AppliedFacetsChips } from "modules/facets/components/applied-facets-chips";
import { FiltersHeader } from "modules/facets/components/filters-header";
import { SaveQuery } from "modules/saved-query/components/save-query";
import { SavedQueryList } from "modules/saved-query/components/saved-query-list/SavedQueryList";
import { Scope } from "modules/scope-metadata/types";
import { useSearchStore } from "modules/search/store";
import React, { useCallback, useEffect, useState } from "react";
import { FacetMenuWidth, FacetMenuWidthMaxHeight } from "./constants";

export enum AppliedFacetChipsLocation {
	NONE = "none",
	CONTROLLER = "controller",
	DRAWER = "drawer",
	SAVED_QUERY = "saved-query",
}

export enum FacetOpenCloseControllerType {
	DRAWER = "drawer",
	MENU = "menu",
}

export interface FacetOpenCloseControllerProps {
	facetsOpen: boolean;
	setFacetsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	children: JSX.Element;
	showFacetToggle?: boolean;
	appliedChipsLocation?: AppliedFacetChipsLocation;
	readOnlyDimensions?: Array<string>;
	isDisabled?: boolean;
	useFacetStore?: CustomCommonStoreType;
	title?: string;
	disableSavedQuery?: boolean;
	dialogTitle?: string;
	controllerType?: FacetOpenCloseControllerType;
	disableSearchChip?: boolean;
	scope?: Scope;
	timeFilterConfig?: {
		shouldShowTimeFilter?: boolean;
		options?: string[];
		defaultValue?: string;
	};
}

export function FacetOpenCloseController({
	facetsOpen,
	setFacetsOpen,
	children,
	showFacetToggle = true,
	appliedChipsLocation = AppliedFacetChipsLocation.CONTROLLER,
	useFacetStore = useCommonStore,
	title,
	dialogTitle,
	readOnlyDimensions,
	isDisabled,
	disableSavedQuery = false,
	controllerType = FacetOpenCloseControllerType.DRAWER,
	disableSearchChip = false,
	scope,
	timeFilterConfig,
}: FacetOpenCloseControllerProps) {
	const theme = useTheme();

	const [appliedCount, setAppliedCount] = useState(0);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const facets = useFacetStore(state => state.facets);

	const selectedTime = useUserPreferencesStore(state =>
		getSelectedTime(state, scope, facets)
	);
	const setSelectedTime = useUserPreferencesStore(state => state.setTimeFilter);

	const updateFacet = useFacetStore(state => state.updateFacet);

	const updatePathOrPortFacet = useCallback(
		(time: string) => {
			updateTimeFilterFacet({
				updateFacet,
				facetName: getSelectedTimeFacetNameFromScope(scope),
				time,
			});
		},
		[scope, updateFacet]
	);

	useEffect(() => {
		if (timeFilterConfig?.shouldShowTimeFilter && scope) {
			updatePathOrPortFacet(selectedTime);
		}
	}, [
		scope,
		selectedTime,
		timeFilterConfig?.shouldShowTimeFilter,
		updatePathOrPortFacet,
	]);

	const setSelectedTimeFilter = useCallback(
		(time: string) => {
			setSelectedTime(time);
			if (scope) {
				updatePathOrPortFacet(time);
			}
		},
		[scope, setSelectedTime, updatePathOrPortFacet]
	);

	const closeAllFacets = useFacetStore(state => state.closeAllFacets);

	const onClose = () => {
		setFacetsOpen(false);
		if (closeAllFacets) {
			closeAllFacets(true);
		}
	};

	useEffect(() => {
		setFacetsOpen(false);
	}, [setFacetsOpen]);

	const fullWidthStyle = {
		width: "100%",
		borderRadius: 30,
		backgroundColor: theme.palette.background.paper,
		backgroundImage:
			theme.palette.mode === "dark"
				? `linear-gradient(${theme.palette.custom.filterBg}, ${theme.palette.custom.filterBg})`
				: "unset",
		border:
			theme.palette.mode === "dark"
				? "unset"
				: `1px solid ${alpha(theme.palette.common.black, 0.08)}`,
	};

	const defaultStyle = {
		ml: 1,
	};

	const search = useSearchStore(state => state.search);
	const setSearch = useSearchStore(state => state.setSearch);

	const onClearSearch = () => {
		if (search && search?.trim().length > 0) {
			setSearch("");
		}
	};

	return (
		<Stack
			direction="column"
			alignItems="center"
			sx={disableSavedQuery ? null : { width: "100%" }}
		>
			<Stack
				direction={"row"}
				alignItems="center"
				sx={disableSavedQuery ? null : { width: "100%" }}
				spacing={0.75}
			>
				<Stack
					alignItems="center"
					direction="row"
					justifyContent="space-between"
					sx={disableSavedQuery ? defaultStyle : fullWidthStyle}
				>
					<Stack alignItems="center" flexWrap={"wrap"} direction="row">
						{showFacetToggle && (
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="flex-end"
							>
								<FiltersHeader
									title={title}
									facetsOpen={facetsOpen}
									setFacetsOpen={setFacetsOpen}
									appliedCount={appliedCount}
									setAnchorEl={setAnchorEl}
									isDisabled={isDisabled}
								/>
							</Stack>
						)}
						<AppliedFacetsChips
							appliedCount={appliedCount}
							onCountFilters={setAppliedCount}
							useFacetStore={useFacetStore}
							readOnlyDimensions={readOnlyDimensions}
							viewOnly={isDisabled}
							showSearchChip={
								!disableSearchChip && search && search?.trim().length > 0 ? (
									<SearchChip
										searchString={search}
										onClearSearch={onClearSearch}
										hideDeleteIcon={false}
									/>
								) : null
							}
						/>
					</Stack>
				</Stack>

				{timeFilterConfig?.shouldShowTimeFilter && (
					<TimeFilter
						defaultTime={timeFilterConfig.defaultValue}
						options={timeFilterConfig.options}
						selectedTime={selectedTime}
						setSelectedTime={setSelectedTimeFilter}
					/>
				)}

				{disableSavedQuery ? null : (
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						flex={1}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="flex-end"
							spacing={0.5}
							sx={{ pr: 1 }}
						>
							<Tooltip title={window.getCTTranslatedText("QuerySave")}>
								<span>
									<SaveQuery useFacetStore={useFacetStore} />
								</span>
							</Tooltip>
							<Tooltip title={window.getCTTranslatedText("QueryViewSaved")}>
								<span>
									<SavedQueryList useFacetStore={useFacetStore} />
								</span>
							</Tooltip>
						</Stack>
					</Stack>
				)}

				<FacetContainer
					facetsOpen={facetsOpen}
					onClose={onClose}
					dialogTitle={dialogTitle}
					appliedChipsLocation={appliedChipsLocation}
					appliedCount={appliedCount}
					setAppliedCount={setAppliedCount}
					useFacetStore={useFacetStore}
					readOnlyDimensions={readOnlyDimensions}
					children={children}
					controllerType={controllerType}
					anchorEl={anchorEl}
				/>
			</Stack>
		</Stack>
	);
}

interface FacetContainerProps {
	facetsOpen: boolean;
	onClose: () => void;
	dialogTitle?: string;
	appliedChipsLocation?: AppliedFacetChipsLocation;
	appliedCount?: number;
	setAppliedCount: React.Dispatch<React.SetStateAction<number>>;
	useFacetStore?: CustomCommonStoreType;
	readOnlyDimensions?: Array<string>;
	children: JSX.Element;
	controllerType?: FacetOpenCloseControllerType;
	anchorEl: null | HTMLElement;
	align?: "left" | "center" | "right" | number;
}

export function FacetContainer({
	facetsOpen,
	onClose,
	dialogTitle,
	appliedChipsLocation,
	appliedCount,
	setAppliedCount,
	useFacetStore,
	readOnlyDimensions,
	children,
	controllerType = FacetOpenCloseControllerType.DRAWER,
	anchorEl,
	align = "left",
}: FacetContainerProps) {
	const content = (
		<>
			{appliedChipsLocation === AppliedFacetChipsLocation.DRAWER && (
				<Box sx={{ ml: 2 }}>
					<AppliedFacetsChips
						appliedCount={appliedCount}
						onCountFilters={setAppliedCount}
						useFacetStore={useFacetStore}
						readOnlyDimensions={readOnlyDimensions}
					/>
				</Box>
			)}
			<Stack sx={{ p: 0, flex: 1, height: "100%" }}>
				<Box
					sx={{
						width: "100%",
						height: "100%",
						visibility: facetsOpen ? "visible" : "hidden",
						opacity: facetsOpen ? 1 : 0,
					}}
				>
					{children}
				</Box>
			</Stack>
		</>
	);

	return (
		<Menu
			anchorEl={anchorEl}
			marginThreshold={0}
			PaperProps={{
				sx: {
					overflowY: "hidden",
					width: FacetMenuWidth,
					maxHeight: FacetMenuWidthMaxHeight,
					height: "100%",
					padding: 0,
					"& .MuiList-root": {
						padding: 0,
						height: "100%",
					},
				},
			}}
			open={Boolean(facetsOpen)}
			onClose={onClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: align,
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: align,
			}}
		>
			<Stack sx={{ minHeight: 0, height: "100%", width: "100%" }}>
				{content}
			</Stack>
		</Menu>
	);
}

interface SearchChipProps {
	searchString: string;
	onClearSearch?: () => void;
	hideDeleteIcon?: boolean;
}

export const SearchChip = ({
	searchString,
	onClearSearch,
	hideDeleteIcon,
}: SearchChipProps) => {
	return (
		<>
			{searchString && searchString?.trim().length > 0 && (
				<Chip
					variant="outlined"
					sx={{ my: 0.5, mx: 0.5 }}
					label={`Search = ${searchString}`}
					deleteIcon={
						hideDeleteIcon ? undefined : <CancelIcon fontSize="small" />
					}
					onDelete={hideDeleteIcon ? undefined : onClearSearch}
				/>
			)}
		</>
	);
};
