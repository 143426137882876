import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useCommonStore } from "common/store";
import { AssetCoreTags } from "modules/asset-core-tags/components";
import { Scope } from "modules/scope-metadata/types";
import {
	UnmanagedDeviceAvailability,
	UnmanagedDeviceIP,
	UnmanagedDeviceMAC,
	UnmanagedDeviceName,
	UnmanagedDeviceVendor,
} from "./components";
import UnmanagedDeviceEditTag from "./components/UnmanagedDeviceEditTag";

export const DEVICE_COLUMNS = (viewOnly: boolean): GridColDef[] => [
	{
		field: "deviceName",
		headerName: "Device Name",
		width: 180,
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceName device={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "deviceCoreTags",
		minWidth: 180,
		headerAlign: "left",
		align: "left",
		sortingOrder: ["desc", "asc"],
		sortable: false,
		headerName: "Tags",
		flex: window.screen.availWidth > 1200 ? 0.5 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetCoreTags
					viewOnly={viewOnly}
					coreTags={params.row.deviceCoreTags}
					useAssetCoreTagsStore={useCommonStore}
					scope={Scope.UnmanagedDevice}
				/>
			);
		},
	},
	{
		field: "deviceIp",
		headerName: "IP Address",
		width: 180,
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceIP device={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "deviceAvailability",
		headerName: "Status",
		headerAlign: "left",
		align: "left",
		width: 180,
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<UnmanagedDeviceAvailability device={params.row} viewOnly={viewOnly} />
			);
		},
	},
	{
		field: "deviceVendor",
		headerName: "Vendor",
		width: 180,
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceVendor device={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "deviceMac",
		headerName: "MAC Address",
		width: 180,
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceMAC device={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "actions",
		headerName: "",
		headerAlign: "left",
		align: "right",
		width: 54,
		minWidth: 54,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceEditTag asset={params.row} />;
		},
	},
];
