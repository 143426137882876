import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { StatusOptionsText } from "pages/ports/constants";
import { PortAllowedStatus, PortRulePreference } from "pages/ports/types";

export interface PortStatusOptionProps {
	updatePortStatus: (status: PortAllowedStatus) => void;
	updatePortRule: (status: PortRulePreference) => void;
	showPortStatus: boolean;
	showProcessOptions: boolean | undefined;
}

export const PortStatusOption = ({
	updatePortStatus,
	updatePortRule,
	showPortStatus,
	showProcessOptions,
}: PortStatusOptionProps) => {
	const theme = useTheme();
	const onPortStatusChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		const status: PortAllowedStatus = value as PortAllowedStatus;
		updatePortStatus(status);
	};
	const onPortRuleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		const status: PortRulePreference = value as PortRulePreference;
		updatePortRule(status);
	};
	return (
		<>
			<Stack
				direction="column"
				sx={{ width: "100%", paddingLeft: "4px", mt: 5 }}
			>
				<Stack direction="row" justifyContent="flex-start">
					<Typography variant="subtitle2">
						Please select port preference as a part of template
					</Typography>
				</Stack>
				<Stack direction="row" justifyContent="flex-start">
					<Typography variant="subtitle2" mt={3} mr={6}>
						Port:
					</Typography>
					<FormControl>
						<RadioGroup
							row
							aria-labelledby="select-port-status"
							name="rule-prefreence-radio-buttons-group"
							defaultValue={PortRulePreference.Port}
							onChange={onPortRuleChange}
						>
							<Stack>
								<FormControlLabel
									value={PortRulePreference.Port}
									control={<Radio size="small" />}
									label={StatusOptionsText[PortRulePreference.Port]}
									sx={{
										pr: 3,
									}}
								/>
								<Typography
									variant="caption"
									color={theme.palette.text.secondary}
									ml={5}
								>
									Selected Port
								</Typography>
							</Stack>
							{showProcessOptions && (
								<>
									<Stack>
										<FormControlLabel
											value={PortRulePreference.Process}
											control={<Radio size="small" />}
											label={StatusOptionsText[PortRulePreference.Process]}
											sx={{
												pr: 3,
											}}
										/>
										<Typography
											variant="caption"
											color={theme.palette.text.secondary}
											ml={5}
										>
											Selected Process & "any" port
										</Typography>
									</Stack>
									<Stack ml={3}>
										<FormControlLabel
											value={PortRulePreference.Port_Process}
											control={<Radio size="small" />}
											label={StatusOptionsText[PortRulePreference.Port_Process]}
											sx={{}}
										/>
										<Typography
											variant="caption"
											color={theme.palette.text.secondary}
											ml={5}
										>
											Selected Port & Process
										</Typography>
									</Stack>
								</>
							)}
						</RadioGroup>
					</FormControl>
				</Stack>
				{showPortStatus && (
					<Stack direction="row" justifyContent="flex-start">
						<Typography variant="subtitle2" mt={3} mr={4}>
							Status:
						</Typography>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby="select-port-status"
								name="port-status-radio-buttons-group"
								defaultValue={PortAllowedStatus.AllowAny}
								onChange={onPortStatusChange}
							>
								<FormControlLabel
									value={PortAllowedStatus.AllowAny}
									control={<Radio size="small" />}
									label={StatusOptionsText[PortAllowedStatus.AllowAny]}
									sx={{
										pr: 3,
									}}
								/>
								<FormControlLabel
									value={PortAllowedStatus.AllowIntranet}
									control={<Radio size="small" />}
									label={StatusOptionsText[PortAllowedStatus.AllowIntranet]}
									sx={{}}
								/>
							</RadioGroup>
						</FormControl>
					</Stack>
				)}
			</Stack>
		</>
	);
};
