import { parseErrorMessage } from "common/utils";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { AssignedTemplatesViewDrawer } from "modules/asset-templates/assigned-templates-view-drawer";
import { useAssetTemplates } from "modules/asset-templates/hooks";
import { MatchedByTemplateType } from "modules/matched-by-templates/constants";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { MatchedByTemplates, Path } from "pages/paths/types";
import { Port } from "pages/ports/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MatchedByTemplateRuleEngine } from "./components";
import { usePathRuleMatchesAPI } from "./hooks";

interface MatchedByTemplateDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	winningTemplates: MatchedByTemplates[];
	id?: string;
	assetId?: string;
	type: MatchedByTemplateType;
	port?: Port;
	path?: Path;
}

export const MatchedByTemplateDrawer = ({
	isOpen,
	onClose,
	winningTemplates,
	id,
	assetId,
	type,
	port,
	path,
}: MatchedByTemplateDrawerProps) => {
	const ruleMatchesMutation = usePathRuleMatchesAPI({ assetId, id, type });
	const { isFeatureEnabled: isRuleEngineEnabled } = useFeatureFlagControl(
		FEATURES.TEMPLATE_EVAL_3
	);

	const ruleMatchesMutate = ruleMatchesMutation.mutate;
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!isRuleEngineEnabled) {
			setIsLoading(false);
			return;
		}

		const body = {};

		ruleMatchesMutate(body, {
			onSuccess() {
				setIsLoading(false);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				setIsLoading(false);
			},
		});
	}, [ruleMatchesMutate, setSnackbar, isRuleEngineEnabled]);

	const getMatchingTemplateData = useCallback(() => {
		const list: MatchedByTemplates[] = [...winningTemplates];
		const pathRuleHash: string[] = [];
		const portRuleHash: string[] = [];

		if (ruleMatchesMutation?.data) {
			const contributingTemplates =
				ruleMatchesMutation?.data?.matchedTemplateDetails;

			const matchedTemplateIDs = Object.keys(contributingTemplates ?? {});

			matchedTemplateIDs.forEach((templateId: string) => {
				pathRuleHash.push(
					...(contributingTemplates?.[templateId]?.pathRuleHash ?? [])
				);
				portRuleHash.push(
					...(contributingTemplates?.[templateId]?.portRuleHash ?? [])
				);

				if (
					winningTemplates.find(template => template.templateId === templateId)
				) {
					return;
				}

				list.push({
					templateId: templateId,
					templateName: "",
				});
			});
		}

		return {
			list,
			pathRuleHash,
			portRuleHash,
		};
	}, [ruleMatchesMutation?.data, winningTemplates]);

	const { list, pathRuleHash, portRuleHash } = useMemo(
		() => getMatchingTemplateData(),
		[getMatchingTemplateData]
	);

	const winningTemplatesIDs = useMemo(
		() =>
			isRuleEngineEnabled
				? winningTemplates.map(template => template.templateId)
				: [],
		[isRuleEngineEnabled, winningTemplates]
	);

	const criteria = `templateid in (${list
		.map(li => `'${li.templateId}'`)
		.join(", ")})`;

	const {
		templateList,
		isLoading: isAssetTemplatesLoading,
		templateListMutation,
		onSortChange,
		sort,
		setPageSize,
		setPage,
		pageSize,
		page,
		totalCount,
	} = useAssetTemplates({ criteria: criteria, enable: isOpen });

	if (!isRuleEngineEnabled) {
		return (
			<AssignedTemplatesViewDrawer
				isOpen={isOpen}
				onClose={onClose}
				templateList={templateList}
				templatesSuggestMutation={templateListMutation}
				isLoading={isAssetTemplatesLoading}
				page={"assets"}
				title={"Matching Templates"}
				onSortChange={onSortChange}
				canDelete={false}
				setPageSize={setPageSize}
				setPage={setPage}
				pageSize={pageSize}
				pageCount={page}
				sort={sort}
				totalCount={totalCount}
				showSearch={false}
			/>
		);
	}

	return isLoading ? null : (
		<MatchedByTemplateRuleEngine
			isOpen={isOpen}
			onClose={onClose}
			list={list}
			winningTemplates={winningTemplatesIDs}
			pathRuleHashList={pathRuleHash}
			portRuleHashList={portRuleHash}
			type={type}
			port={port}
			path={path}
		/>
	);
};
