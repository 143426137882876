import CloseIcon from "@mui/icons-material/Close";
import {
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GridRowId, GridRowParams, GridSortItem } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { UserGroupStatusActionBar } from "../../pages/user-groups/components/user-group-status-action-bar";
import {
	UserGroup,
	HostAndUserGroupStatusState,
} from "pages/users-segmentation/components/user-groups/types";
import { BASE_USERGROUPS_COLUMNS } from "pages/users-segmentation/components/user-groups/components/user-group-data-grid/constants";
import { HOST_GROUPS_COLUMNS } from "pages/host-groups/components/host-groups-data-grid/constants";
import { HostGroup } from "pages/host-groups/types";

export enum DrawerType {
	UserGroup = "user group",
	HostGroup = "host group",
}

interface HostAndUserGroupChangeStatusDrawerProps<T> {
	isOpen: boolean;
	onClose: () => void;
	selectedData?: T[];
	selectedStatus?: HostAndUserGroupStatusEnum;
	confirm: Function;
	drawerType: DrawerType;
}

export enum HostAndUserGroupStatusEnum {
	Enable = "Active",
	Disable = "Inactive",
}

export const HostAndUserGroupChangeStatusDrawer = <T,>({
	isOpen,
	onClose,
	selectedStatus,
	selectedData,
	confirm,
	drawerType,
}: HostAndUserGroupChangeStatusDrawerProps<T>) => {
	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	useEffect(() => {
		if (selectedData?.length) {
			if (drawerType === DrawerType.UserGroup) {
				const selectedGroupMap: string[] = (selectedData as UserGroup[])
					.filter(row =>
						selectedStatus !== HostAndUserGroupStatusEnum.Enable
							? row?.userGroupStatus === HostAndUserGroupStatusState.Active
							: row?.userGroupStatus === HostAndUserGroupStatusState.InActive
					)
					.map((row?) => row.groupID);
				setSelection(selectedGroupMap);
			}

			if (drawerType === DrawerType.HostGroup) {
				const selectedGroupMap: string[] = (selectedData as HostGroup[])
					.filter(row =>
						selectedStatus !== HostAndUserGroupStatusEnum.Enable
							? row?.hostgroupstatus === HostAndUserGroupStatusState.Active
							: row?.hostgroupstatus === HostAndUserGroupStatusState.InActive
					)
					.map((row?) => row.id);
				setSelection(selectedGroupMap);
			}
		}
	}, [selectedData, selectedStatus, drawerType]);

	const confirmAction = () => {
		confirm();
	};

	if (!selectedData) {
		return null;
	}

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title="Close Drawer">
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ mt: 4, mb: 0, mx: 4 }}>
				<Typography variant="h5">
					<b>{window.getCTTranslatedText(getTitle(drawerType))}</b>
				</Typography>
				<Typography variant="h5">{`: ${window.getCTTranslatedText(
					selectedStatus ?? ""
				)}`}</Typography>
			</Stack>
			<DialogContent>
				<DataGrid
					rowHeight={64}
					columns={getColumns(drawerType)}
					pagination={true}
					getRowId={getRowId(drawerType)}
					paginationMode="client"
					sortingMode="client"
					checkboxSelection
					isRowSelectable={data =>
						getIsRowSelectable(drawerType, data, selectedStatus)
					}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					rows={selectedData}
					rowCount={selectedData?.length ?? 0}
					selectedRawData={selectedData}
					initialState={{
						sorting: {
							sortModel: getSortModel(drawerType),
						},
					}}
				/>
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<UserGroupStatusActionBar
					selectedStatus={selectedStatus}
					selection={selection}
					drawerType={drawerType}
					update={confirmAction}
					cancel={onClose}
				/>
			</DialogActions>
		</Drawer>
	);
};

const getSortModel = (drawerType: DrawerType) => {
	switch (drawerType) {
		case DrawerType.UserGroup:
			return [{ field: "groupName", sort: "desc" }] as GridSortItem[];
		case DrawerType.HostGroup:
			return [
				{ field: "hostgroupstatus", sort: "desc" },
				{ field: "hostgroupname", sort: "desc" },
			] as GridSortItem[];
	}
};

const getColumns = (drawerType: DrawerType) => {
	switch (drawerType) {
		case DrawerType.UserGroup:
			return BASE_USERGROUPS_COLUMNS;
		case DrawerType.HostGroup:
			return HOST_GROUPS_COLUMNS;
	}
};

const getTitle = (drawerType: DrawerType) => {
	switch (drawerType) {
		case DrawerType.UserGroup:
			return "ChangeGroupStatus";
		case DrawerType.HostGroup:
			return "ChangeHostGroupStatus";
	}
};

const getRowId = (drawerType: DrawerType) => {
	switch (drawerType) {
		case DrawerType.UserGroup:
			return (group: UserGroup) => `${group.groupID}`;
		case DrawerType.HostGroup:
			return (group: HostGroup) => `${group.id}`;
	}
};

const getIsRowSelectable = (
	drawerType: DrawerType,
	data: GridRowParams<any>,
	selectedStatus?: HostAndUserGroupStatusEnum
) => {
	switch (drawerType) {
		case DrawerType.UserGroup:
			return selectedStatus !== HostAndUserGroupStatusEnum.Enable
				? data.row.userGroupStatus === HostAndUserGroupStatusState.Active
				: data.row.userGroupStatus === HostAndUserGroupStatusState.InActive;
		case DrawerType.HostGroup:
			return selectedStatus !== HostAndUserGroupStatusEnum.Enable
				? data.row.hostgroupstatus === HostAndUserGroupStatusState.Active
				: data.row.hostgroupstatus === HostAndUserGroupStatusState.InActive;
	}
};
