import { DataGrid } from "modules/data-grid/components/data-grid";
import { BASE_CONTAINERS_COLUMNS } from "./constants";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { Container } from "pages/containers/types";
import { Box, Stack } from "@mui/system";
import { ContainersToolbar } from "../containers-data-grid-toolbar";

export const ContainerDataGrid = (props: DataGridProps<Container>) => {
	const hideToolbar = (): boolean => false;

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<ContainersToolbar show={true} hideToolbar={hideToolbar} />
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					rowHeight={64}
					columns={BASE_CONTAINERS_COLUMNS}
					pagination
					getRowId={({ agentId }: Container) => `${agentId}`}
					paginationMode="server"
					sortingMode="server"
					{...props}
				/>
			</Box>
		</Stack>
	);
};
