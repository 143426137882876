import CancelIcon from "@mui/icons-material/Cancel";
import { Chip, ChipProps, Stack } from "@mui/material";
import { AppliedFacetChipsLocation } from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { useCommonStore } from "common/store";
import { CustomCommonStoreType } from "common/types/types";
import {
	getFacetDisplayName,
	getFacetOptionDisplayText,
} from "modules/facets/helpers";
import { FacetOptionState, FacetState, Operator } from "modules/facets/types";
import { getLabelFromDisplayValues } from "modules/facets/utils/getLabelFromDisplayValues";
import { getOptionLabel } from "modules/facets/utils/getOptionLabel";
import { isTimeFilterOption } from "modules/facets/utils/isTimeFilterOption";
import { CommonFieldTypes } from "modules/saved-query/constants";
import { FacetOptionUnit, ScopeMetadata } from "modules/scope-metadata/types";
import { useSearchStore } from "modules/search/store";
import { useEffect, useMemo, useRef } from "react";
import { FilterActionButton } from "../filter-action-button";

export type FilterCountCallback = (count: number) => void;

export function AppliedFacetsChips({
	onCountFilters,
	appliedCount,
	useFacetStore = useCommonStore,
	readOnlyDimensions,
	facetState,
	appliedChipsLocation = AppliedFacetChipsLocation.CONTROLLER,
	facetChipProps,
	minRequired,
	sortByName,
	showSearchChip,
	viewOnly,
	metadata,
}: {
	appliedCount?: number;
	onCountFilters?: FilterCountCallback;
	useFacetStore?: CustomCommonStoreType;
	readOnlyDimensions?: Array<string>;
	facetState?: FacetState;
	appliedChipsLocation?: AppliedFacetChipsLocation;
	facetChipProps?: ChipProps;
	minRequired?: number;
	sortByName?: boolean;
	viewOnly?: boolean;
	showSearchChip?: JSX.Element | null;
	metadata?: ScopeMetadata;
}) {
	const storeMetadata = useFacetStore(state => state.metadata);
	const metadataToUse = metadata ?? storeMetadata;
	const facets = useFacetStore(state => state.facets);

	const filteredFacets = useMemo(() => {
		const tempFacets = new Map(facetState || facets);
		const keys = Array.from(tempFacets?.keys() || []);
		if (!facetState) {
			keys.forEach(key => {
				if (
					(!metadataToUse?.columns[key] &&
						!metadataToUse?.namesToColumn?.[key]) ||
					readOnlyDimensions?.includes(key)
				) {
					tempFacets?.delete(key);
				}
			});
		}

		return tempFacets;
	}, [facets, metadataToUse, readOnlyDimensions, facetState]);

	const facetNames = Array.from(filteredFacets?.keys() || []);
	useEffect(() => {
		let count = 0;
		filteredFacets?.forEach(value => {
			if (value) {
				count += value.size;
			}
		});
		if (onCountFilters) {
			onCountFilters(count);
		}
	}, [filteredFacets, onCountFilters]);

	const setFacetState = useFacetStore(state => state.setFacets);

	const facetNamesSorted = useMemo(() => {
		if (sortByName) {
			return facetNames?.sort((a, b) => a.localeCompare(b));
		}
		return facetNames;
	}, [facetNames, sortByName]);

	const search = useSearchStore(state => state.search);
	const setSearch = useSearchStore(state => state.setSearch);

	const onClearSearch = () => {
		if (showSearchChip && search && search?.trim().length > 0) {
			setSearch("");
		}
	};

	return (
		<Stack
			className="facet-chips"
			direction="row"
			alignItems={"center"}
			justifyItems="flex-start"
			flexWrap={"wrap"}
			sx={{ maxWidth: "100%" }}
		>
			{showSearchChip}
			{facetNamesSorted?.map(facetName => {
				const localFacetState =
					facetState?.get(facetName) || facets?.get(facetName);
				return (
					<AppliedFacetChip
						chipProps={facetChipProps}
						key={facetName}
						facetName={facetName}
						facetOptionState={localFacetState}
						useFacetStore={useFacetStore}
						useCommonFacetName={
							appliedChipsLocation === AppliedFacetChipsLocation.SAVED_QUERY
						}
						hideDeleteIcon={
							appliedChipsLocation === AppliedFacetChipsLocation.SAVED_QUERY ||
							readOnlyDimensions?.includes(facetName) ||
							facetNames?.length <= (minRequired ?? 0) ||
							viewOnly
						}
					/>
				);
			})}

			{!viewOnly &&
				!minRequired &&
				appliedChipsLocation !== AppliedFacetChipsLocation.SAVED_QUERY &&
				filteredFacets &&
				(filteredFacets.size > 1 ||
					(filteredFacets.size === 1 &&
						showSearchChip &&
						search &&
						search?.trim().length > 0)) && (
					<FilterActionButton
						sx={{ px: 2 }}
						color="inherit"
						size="medium"
						onClick={() => {
							const newFacets = new Map(facetState || facets);
							let keys = Array.from(newFacets.keys());
							keys = keys.filter(key => !readOnlyDimensions?.includes(key));
							keys.forEach(key => newFacets.delete(key));
							setFacetState(newFacets);
							onClearSearch();
						}}
					>
						{window.getCTTranslatedText("Clear All")}
					</FilterActionButton>
				)}
		</Stack>
	);
}

export interface AppliedFacetChipProps {
	facetName: string | undefined;
	facetOptionState: FacetOptionState | undefined;
	hideDeleteIcon?: boolean;
	useFacetStore?: CustomCommonStoreType;
	useCommonFacetName?: boolean;
	chipProps?: ChipProps;
}

export function AppliedFacetChip({
	facetName,
	facetOptionState,
	hideDeleteIcon,
	useCommonFacetName = false,
	useFacetStore = useCommonStore,
	chipProps,
}: AppliedFacetChipProps) {
	const metadata = useFacetStore(state => state.metadata);
	let operator = useRef(Operator.EQUAL);
	const selectedOptions = useMemo<Array<string>>(() => {
		const options: Array<string> = [];
		facetOptionState?.forEach((value, key) => {
			if (value.isSelected) {
				let text = window.getCTTranslatedText(key);
				if (facetName) {
					const valuesDisplayOptions =
						metadata?.columns[facetName]?.valuesDisplayOptions;
					if (valuesDisplayOptions?.[key]) {
						text = getLabelFromDisplayValues(key, valuesDisplayOptions);
					}

					const unit = metadata?.columns[facetName]?.unit;
					if (unit) {
						text = getOptionLabel(key, unit);
					}
				}

				operator.current = value.operator ?? Operator.EQUAL;
				options.push(text);
			}
		});
		return options;
	}, [facetOptionState, facetName, metadata?.columns]);

	const updateFacet = useFacetStore(state => state.updateFacet);

	if (!facetOptionState || !facetName) {
		return null;
	}

	const facetDisplayName = getFacetDisplayName(
		facetName,
		metadata,
		useCommonFacetName
	);

	const getFacetValueText = (option: string) => {
		if (
			facetDisplayName === CommonFieldTypes.Duration &&
			isTimeFilterOption(option)
		) {
			return window.getCTTranslatedText(
				getOptionLabel(option, FacetOptionUnit.Hour)
			);
		}

		return window.getCTTranslatedText(getFacetOptionDisplayText(option));
	};

	return (
		<>
			<Chip
				variant="outlined"
				{...chipProps}
				onClick={() => {
					updateFacet({
						facetName: facetName,
						options: facetOptionState,
					});
				}}
				sx={{
					my: 0.5,
					mx: 0.5,
					...chipProps?.sx,
				}}
				label={`${window.getCTTranslatedText(facetDisplayName)} ${
					operator.current
				} ${selectedOptions.map(getFacetValueText).join(" OR ")}`}
				onDelete={
					hideDeleteIcon
						? undefined
						: () => {
								updateFacet({
									facetName: facetName,
									options: new Map(),
								});
							}
				}
				deleteIcon={
					hideDeleteIcon ? undefined : <CancelIcon fontSize="small" />
				}
			/>
		</>
	);
}
