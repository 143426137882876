import CloseIcon from "@mui/icons-material/Close";
import {
	Button,
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { Template } from "pages/templates/types";
import React, { useEffect, useState } from "react";

export const CreateTemplateDrawer = ({
	setSelectedTemplate,
	clearError,
	autoSuggestTemplate,
	autoSuggestSegmentName,
}: {
	setSelectedTemplate: (data?: Template) => void;
	clearError: () => void;
	autoSuggestTemplate?: boolean;
	autoSuggestSegmentName?: string;
}) => {
	const [formData, setFormData] = useState<Template | undefined>();
	const [showCreateNewTemplateDrawer, setShowCreateNewTemplateDrawer] =
		useState(false);

	const handlerOnClose = () => setShowCreateNewTemplateDrawer(false);
	useEffect(() => {
		if (autoSuggestTemplate) {
			const newData = {
				templateName: autoSuggestSegmentName,
			} as Template;
			setFormData(newData);
			setSelectedTemplate(newData);
		} else {
			setFormData({} as Template);
			setSelectedTemplate({} as Template);
		}
	}, [autoSuggestTemplate, setSelectedTemplate, autoSuggestSegmentName]);

	const handlerOnSave = () => {
		setSelectedTemplate(formData);
		clearError();
		handlerOnClose();
	};

	return (
		<>
			<Button
				variant="text"
				sx={{ width: "fit-content" }}
				onClick={() => setShowCreateNewTemplateDrawer(true)}
			>
				{autoSuggestTemplate
					? window.getCTTranslatedText("Edit template")
					: window.getCTTranslatedText("Create New Template")}
			</Button>

			{showCreateNewTemplateDrawer && (
				<Drawer
					anchor="right"
					open={showCreateNewTemplateDrawer}
					onClose={handlerOnClose}
					PaperProps={{
						sx: {
							padding: "0px",
							width: "40%",
							minWidth: "1000px",
							height: "100%",
						},
						elevation: 1,
					}}
				>
					<Toolbar />
					<Stack
						alignItems="flex-start"
						sx={{ position: "relative", width: "100%" }}
					>
						<Tooltip title="Close Drawer">
							<IconButton
								size="medium"
								aria-label="close drawer"
								onClick={handlerOnClose}
								sx={{
									position: "absolute",
									right: "16px",
									top: "24px",
									zIndex: 2,
								}}
							>
								<CloseIcon fontSize="medium" />
							</IconButton>
						</Tooltip>

						<Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
							<Typography variant="h5">
								<b>
									{autoSuggestTemplate
										? window.getCTTranslatedText("Edit template")
										: window.getCTTranslatedText("Create Template")}
								</b>
							</Typography>
						</Stack>
					</Stack>

					<DialogContent
						sx={{
							pt: 0,
							display: "flex",
							flexDirection: "column",
							height: "100%",
						}}
					>
						<TemplateForm formData={formData} setFormData={setFormData} />
					</DialogContent>

					<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
						<ToolbarAction
							loading={false}
							isValid={Boolean(formData?.templateName)}
							actionBtnText={"save"}
							save={handlerOnSave}
							cancel={handlerOnClose}
							hasPermission={true}
						/>
					</DialogActions>
				</Drawer>
			)}
		</>
	);
};

export interface FormData {
	templateName: string;
	templateDescription: string;
}

const TemplateForm = ({
	formData,
	setFormData,
}: {
	formData?: Template;
	setFormData: React.Dispatch<React.SetStateAction<Template | undefined>>;
}) => {
	const handleUpdate = (field: string, value: string) => {
		setFormData(
			prevData =>
				({
					...prevData,
					[field]: value,
				}) as Template
		);
	};

	return (
		<>
			<CTInputField
				field="templateName"
				displayName="Template Name"
				value={formData?.templateName}
				handleUpdate={(event: TextFieldUpdate) =>
					handleUpdate(event.field, event.value)
				}
			/>
			<CTInputField
				field="templateDescription"
				displayName="Description"
				value={formData?.templateDescription}
				handleUpdate={(event: TextFieldUpdate) =>
					handleUpdate(event.field, event.value)
				}
			/>
		</>
	);
};
