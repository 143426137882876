import { Button, Grid, GridProps, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { Loader } from "common/atoms/loader";
import { useCommonStore } from "common/store";
import { useDemoController } from "hooks/useDemoController";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { useAssetAggregateAPI } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import AssetBanner from "pages/asset/components/asset-detail/AssetBanner";
import { useAssetsAPI } from "pages/assets/components/asset-data-grid/hooks";
import { useAssetsFacetsOrder } from "pages/assets/constants";
import { useAssetStore } from "pages/assets/store";
import { Asset, AssetSecurityCategory, SecurityType } from "pages/assets/types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RecommendationWidgetWrapper } from "./components/recommendation-widget-wrapper";
import { RiskChartWrapper } from "./components/risk-chart-wrapper";
import { SecurityProgressChartWrapper } from "./components/security-progress-chart-wrapper";
import { SecurityStatusChartWrapper } from "./components/security-status-chart-wrapper";
import { SegmentationProgressChartWrapper } from "./components/segmentation-progress-chart-wrapper";
import { WelcomeScreen } from "./components/welcome-screen";

export const Dashboard = () => {
	const theme = useTheme();

	const assetCoreResponse = useCore<Asset>({
		useStore: useAssetStore,
		facetGroupInfo: useAssetsFacetsOrder(),
		scope: Scope.Asset,
		useApi: useAssetsAPI,
		pageSize: 10,
		defaultSortOrder: [{ field: "assetrisk", order: "desc" }],
		includeFacetFields: [],
	});

	const [showAssetBanner, setShowAssetBanner] = useState(true);
	const [limitReachedAssetsCount, setLimitReachedAssetsCount] = useState(0);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const assetAggMutation = useAssetAggregateAPI();
	const assetAggMutate = assetAggMutation.mutate;

	useEffect(() => {
		const requestObj = {
			criteria: "'newpathprocessingstopped' in (true)",
			scope: Scope.Asset,
			groupBy: [],
			statistics: ["count(assetid)"],
		};
		assetAggMutate(requestObj, {
			onSuccess(responseData) {
				const assetIdCount = Number(
					responseData?.items?.statistics?.assetidcount ?? 0
				);
				setLimitReachedAssetsCount(assetIdCount);
			},
			onError: error => {
				setSnackbar(
					true,
					SnackBarSeverity.Error,
					"Failed to fetch newPathProcessingStopped for assets"
				);
			},
		});
	}, [setSnackbar, assetAggMutate]);

	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);
	const { isDemo } = useDemoController();

	const metadata = useScopeMetadata({ scope: Scope.Asset });
	const scope = useCommonStore(state => state.scope);

	const isLoading =
		metadata?.isLoading ||
		(searchCriteria === "*" &&
			(assetCoreResponse.mutation.isLoading ||
				assetCoreResponse.mutation.isIdle));

	if (isLoading || scope !== Scope.Asset) {
		return <Loader />;
	}

	if (
		searchCriteria === "*" &&
		assetCoreResponse.mutation.isSuccess &&
		assetCoreResponse.rowCount === 0 &&
		!isDemo
	) {
		return <WelcomeScreen />;
	}

	interface CardType {
		component: JSX.Element;
		gridProps?: GridProps;
	}

	const cards: CardType[] = [
		{
			component: <RiskChartWrapper />,
		},
		{
			component: (
				<SecurityProgressChartWrapper type={SecurityType.ATTACK_SURFACE} />
			),
		},
		{
			component: (
				<SecurityProgressChartWrapper type={SecurityType.BLAST_RADIUS} />
			),
		},
		{
			component: <SegmentationProgressChartWrapper type={"untagged"} />,
		},
		{
			component: (
				<SecurityStatusChartWrapper
					type={SecurityType.ATTACK_SURFACE}
					direction={AssetSecurityCategory.INBOUND}
				/>
			),
		},
		{
			component: (
				<SecurityStatusChartWrapper
					type={SecurityType.BLAST_RADIUS}
					direction={AssetSecurityCategory.OUTBOUND}
				/>
			),
		},
	];

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			{showAssetBanner && !!limitReachedAssetsCount && (
				<AssetBanner
					setShowAssetBanner={setShowAssetBanner}
					text={window.getCTTranslatedText("AssetBannerDashboardText", {
						assetCount: limitReachedAssetsCount,
					})}
					fromDashboard={true}
				/>
			)}
			<Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }}>
				<FacetOpenCloseController
					facetsOpen={assetCoreResponse.facetsOpen}
					setFacetsOpen={assetCoreResponse.setFacetsOpen}
				>
					<FacetControllerGroup
						config={assetCoreResponse.facetConfig}
						value={assetCoreResponse.facetState}
						onChange={assetCoreResponse.updateFacet}
					/>
				</FacetOpenCloseController>
				<Link to="/reports">
					<Button
						variant="contained"
						color="primary"
						onClick={() => {}}
						sx={{
							ml: 2,
						}}
					>
						{window.getCTTranslatedText("Reports")}
					</Button>
				</Link>
			</Stack>
			<Stack
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					flex: 1,
					minWidth: 0,
					height: "100%",
					mb: 1,
					pb: 1,
					overflowY: "auto",
				}}
				spacing={3}
			>
				<Stack direction={"row"} sx={{ width: "100%" }}>
					<Grid container spacing={3}>
						{cards.map(({ component, gridProps }, i) => (
							<Grid key={`card-${i}`} item xs={12} md={6} lg={4} {...gridProps}>
								{component}
							</Grid>
						))}
					</Grid>
				</Stack>
				<Stack direction={"row"} sx={{ width: "100%" }} pb={4}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<RecommendationWidgetWrapper />
						</Grid>
					</Grid>
				</Stack>
			</Stack>
		</Stack>
	);
};
