import {
	Agent,
	AgentCommands,
	AgentStatusType,
	AgentType,
	PendingCommands,
} from "pages/agents/types";
import { Asset, AssetType } from "pages/assets/types";
import * as semver from "semver";
import { PLATFORMS } from "../../agent-download-page/constants";
import {
	AgentActionStatusInfo,
	AgentDebugLogStates,
	AgentDebugLogSupportedDevVersion,
	AgentDebugLogSupportedMinVersion,
	AgentDecommissionSupportedDevVersion,
	AgentDecommissionSupportedMinVersion,
	AgentDiagnosticSupportedDevVersion,
	AgentDiagnosticSupportedMinVersion,
	AgentNsTrafficConfigSupportedDevVersion,
	AgentNsTrafficConfigSupportedMinVersion,
	AgentPolicyTamperMonitoringSuppportedDevVersion,
	AgentPolicyTamperMonitoringSuppportedMinVersion,
	AgentPolicyTamperingStates,
	AgentTrafficConfigSupportedDevVersion,
	AgentTrafficConfigSupportedMinVersion,
} from "../constants";

export const compareVersions = (
	version1: string,
	version2: string
): boolean => {
	const v1 = semver.coerce(version1) ?? "0.0.0";
	const v2 = semver.coerce(version2) ?? "0.0.0";
	return semver.gte(v1, v2);
};

export const isUpgradeEnabled = (agent: Agent) =>
	agent.agentUpgradeStatus === AgentActionStatusInfo.Synchronized &&
	agent.agentUpgradeAvailable &&
	Boolean(agent.agentId);

export const isRestartEnabled = (agent: Agent) => {
	return (
		agent.agentRestartStatus === AgentActionStatusInfo.Synchronized &&
		Boolean(agent.agentId)
	);
};
export const isDiagnosticsRunning = (agent: Agent) => {
	return (
		agent.agentDiagnosticsStatus === AgentActionStatusInfo.Pending &&
		Boolean(agent.agentId)
	);
};

export const isDiagnosticsSupported = (agent: Agent) => {
	const currentVersion = agent?.currentVersion;
	if (currentVersion === AgentDiagnosticSupportedDevVersion) {
		return true;
	}
	return compareVersions(currentVersion, AgentDiagnosticSupportedMinVersion);
};

export const isDiagnosticsAllowed = (agent: Agent) => {
	return (
		isDiagnosticsSupported(agent) &&
		agent?.agentStatus === AgentStatusType.Active
	);
};

export const isDecommissionEnabled = (agent: Agent) => {
	return (
		agent.agentDecommissionStatus === AgentActionStatusInfo.Synchronized &&
		Boolean(agent.agentId)
	);
};

export const isDecommissionSupported = (agent: Agent) => {
	if (agent.agentStatus === AgentStatusType.Absent) {
		return true;
	}
	const currentVersion = agent?.currentVersion;
	if (currentVersion === AgentDecommissionSupportedDevVersion) {
		return true;
	}
	return compareVersions(currentVersion, AgentDecommissionSupportedMinVersion);
};

export const isDebugLogEnabled = (agent: Agent) => {
	if (agent.debugLogState === AgentDebugLogStates.Enabled) {
		return true;
	}
	return false;
};
export const isPolicyTamperingEnabled = (agent: Agent) => {
	if (
		agent.currentPolicyTamperMonitoringConfiguration ===
		AgentPolicyTamperingStates.Enabled
	) {
		return true;
	}
	return false;
};

export const isPolicyTamperingRunning = (agent: Agent) => {
	const policyTamperingStatus = getAgentStatus({
		agent,
		agentCommand: AgentCommands.POLICY_TAMPER_MONITOR,
	});
	return (
		policyTamperingStatus === AgentActionStatusInfo.Pending &&
		Boolean(agent.agentId)
	);
};

export const isDebugLogRunning = (agent: Agent) => {
	return (
		agent.agentDebugLogStatus === AgentActionStatusInfo.Pending &&
		Boolean(agent.agentId)
	);
};

export const isDebugLogSupported = (agent: Agent) => {
	if (agent.agentType === AgentType.CT_LEGACY_AGENT) {
		return false;
	}
	if (agent.agentStatus === AgentStatusType.Absent) {
		return false;
	}
	const currentVersion = agent?.currentVersion;
	if (currentVersion === AgentDebugLogSupportedDevVersion) {
		return true;
	}
	return compareVersions(currentVersion, AgentDebugLogSupportedMinVersion);
};

export const isPolicyTamperingSupported = (
	agent: Agent,
	isAgentDevVersionEnabled?: boolean
) => {
	if (agent.platform === PLATFORMS.DARWIN) {
		return false;
	}
	const currentVersion = agent?.currentVersion;
	if (isAgentDevVersionEnabled) {
		return compareVersions(
			currentVersion,
			AgentPolicyTamperMonitoringSuppportedDevVersion
		);
	} else {
		return compareVersions(
			currentVersion,
			AgentPolicyTamperMonitoringSuppportedMinVersion
		);
	}
};

export const isTrafficConfigurationSupported = (agent: Agent) => {
	if (agent?.assetType !== AssetType.Endpoint) {
		return false;
	}
	const currentVersion = agent?.currentVersion;
	if (currentVersion === AgentTrafficConfigSupportedDevVersion) {
		return true;
	}
	return compareVersions(currentVersion, AgentTrafficConfigSupportedMinVersion);
};

export const isNorthSouthTrafficConfigurationSupported = (agent: Agent) => {
	if (agent?.assetType !== AssetType.Server) {
		return false;
	}
	const currentVersion = agent?.currentVersion;
	if (currentVersion === AgentNsTrafficConfigSupportedDevVersion) {
		return true;
	}
	return isHigherVersion(
		AgentNsTrafficConfigSupportedMinVersion,
		currentVersion
	);
};

export const getAgentStatus = ({
	agent,
	agentCommand,
}: {
	agent: Agent;
	agentCommand: AgentCommands;
}) => {
	const isAgentStatusPending = (agent?.pendingCommands || []).some(
		(command: PendingCommands) => {
			return command.action === agentCommand;
		}
	);
	return isAgentStatusPending
		? AgentActionStatusInfo.Pending
		: AgentActionStatusInfo.Synchronized;
};

export const getAssetType = (asset: Asset) => {
	if (asset) {
		return asset?.type ?? "";
	}
	return "";
};

export const isHigherVersion = (
	version1: string,
	version2: string
): boolean => {
	const v1 = semver.coerce(version1) ?? "0.0.0";
	const v2 = semver.coerce(version2) ?? "0.0.0";
	return semver.lt(v1, v2);
};

export const isHigherOrEqualVersions = (
	version1: string,
	version2: string
): boolean => {
	const v1 = semver.coerce(version1) ?? "0.0.0";
	const v2 = semver.coerce(version2) ?? "0.0.0";
	return semver.gt(v1, v2);
};
