import { Box, Stack, Typography } from "@mui/material";
import { AppliedFacetChipsLocation } from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { CustomCommonStoreType } from "common/types/types";
import { AppliedFacetsChips } from "modules/facets/components/applied-facets-chips";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import { useTagPolicyFacetStore } from "../../store";
import { TagPolicy } from "../../types";

export function PolicyCriteria({
	policy,
	useFacetStore = useTagPolicyFacetStore,
}: {
	policy: TagPolicy;
	useFacetStore?: CustomCommonStoreType;
}) {
	if (!policy.tagBasedPolicyId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	let urlParams = new URLSearchParams(policy.criteriaAsParams);
	let filters = decodeURIComponent(urlParams.get("filters") ?? "");
	let search = urlParams.get("search");

	let facetState = decodeThrowable(filters);
	if (!facetState && search) {
		facetState = new Map();
	}

	if (search && facetState) {
		let options = new Map();
		options.set(search, {
			isSelected: true,
		});
		facetState?.set("Query", options);
	}

	const facetHasKey = Array.from(facetState?.keys() || [])?.[0]?.length > 0;

	return (
		<Stack
			sx={{
				ml: facetHasKey ? -1 : 0,
				width: "100%",
				"& .facet-chips": {
					flexWrap: "nowrap",
				},
			}}
		>
			{facetHasKey ? (
				<>
					<AppliedFacetsChips
						useFacetStore={useFacetStore}
						appliedChipsLocation={AppliedFacetChipsLocation.SAVED_QUERY}
						facetState={facetState}
						sortByName={true}
						facetChipProps={{
							size: "small",
							sx: {
								fontSize: "12px",
							},
						}}
					/>
				</>
			) : (
				<>
					<Typography variant="body2">{policy?.criteria}</Typography>
				</>
			)}
		</Stack>
	);
}
