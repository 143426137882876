import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import DnsIcon from "@mui/icons-material/DnsOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import StoreIcon from "@mui/icons-material/Store";
import { Box, Stack, styled, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
	AIXIcon,
	AppleIcon,
	DockerIcon,
	LinuxIcon,
	SolarisIcon,
	WindowsIcon,
} from "assets/svgs";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import {
	Device_CORE_TAGS,
	NOT_SERVICE_CORE_TAGS,
	SERVICE_CORE_TAGS,
} from "modules/scope-metadata/constants";
import { ScopeMetadataColumn } from "modules/scope-metadata/types";
import {
	AssetMetaDataReadonlyProps,
	AssetType,
	CoreTagsKeys,
	User,
} from "pages/assets/types";
import { useTagsAPI } from "pages/tags/hooks/useTagsAPI";
import { useMemo } from "react";
import { AssetCoreTag } from "../asset-core-tag/AssetCoreTag";
import { MetaDataItem } from "../asset-metadata-wrapper/components/metadata-item";
import { getAssetInterfaceIPs } from "./helpers";

interface fieldsProps {
	label: string;
	value?: string | Array<User>;
	icon: JSX.Element;
}

// const ipv4_regex =
//   /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm;

const CenterComponent = ({ children }: { children: JSX.Element }) => {
	return (
		<>
			<Stack
				alignContent={"center"}
				justifyItems={"center"}
				justifyContent={"center"}
			>
				{children}
			</Stack>
		</>
	);
};

export function MetaDataItemWrapper({ asset }: AssetMetaDataReadonlyProps) {
	const theme = useTheme();

	const { tagFields: tags } = useTagsAPI();

	const userDefinedTagsList = useMemo(() => {
		if (!tags) {
			return;
		}
		return tags?.userDefinedTags.map(col => col.displayName ?? "") ?? [];
	}, [tags]);

	const hasCommonFields = (tagName?: string) => {
		if (
			tagName !== "osname" &&
			tagName !== "businessvalue" &&
			tagName !== "type" &&
			tagName !== "kernelversion" &&
			tagName !== "serialnumber"
		) {
			return false;
		}
		return true;
	};

	const coreTagList = useMemo(() => {
		if (!tags) {
			return;
		}
		if (asset?.type !== AssetType.Service) {
			tags.coreTags = tags.coreTags.filter((tag: ScopeMetadataColumn) => {
				return tag?.name && !SERVICE_CORE_TAGS[tag?.name];
			});
		}
		if (asset?.type === AssetType.Service) {
			tags.coreTags = tags.coreTags.filter((tag: ScopeMetadataColumn) => {
				return tag?.name && !NOT_SERVICE_CORE_TAGS[tag?.name];
			});
		}
		if (asset?.type !== AssetType.Device) {
			tags.coreTags = tags.coreTags.filter((tag: ScopeMetadataColumn) => {
				return tag?.name && !Device_CORE_TAGS[tag?.name];
			});
		}

		tags.coreTags = tags.coreTags.filter((tag: ScopeMetadataColumn) => {
			return tag?.name && !hasCommonFields(tag?.name);
		});

		const coreTags = tags?.coreTags.map(col => col.name ?? "") ?? [];
		const assetCoreTags = Object.entries(asset?.coreTags ?? {})
			.filter(([key, value]) => {
				return (
					value && !userDefinedTagsList?.includes(key) && !hasCommonFields(key)
				);
			})
			.map(([key]) => key);

		return sortBy(uniq([...assetCoreTags, ...coreTags]));
	}, [asset?.coreTags, asset?.type, tags, userDefinedTagsList]);

	const iconStyle = useMemo(
		() => ({
			color:
				theme.palette.mode === "dark"
					? "rgba(255, 255, 255, 0.38)"
					: "rgba(0, 0, 0, 0.38)",
		}),
		[theme.palette.mode]
	);

	if (asset?.type === AssetType.Service) {
		asset.osName = "container";
	}

	const Item = styled(Box)(({ theme }) => ({
		textAlign: "left",
	}));

	const fields: fieldsProps[] = useMemo(() => {
		const getOsIcon = (osName: string = "") => {
			const color =
				theme.palette.mode === "dark"
					? "rgba(255, 255, 255, 0.38)"
					: "rgba(0, 0, 0, 0.38)";

			const solarisIcon = <SolarisIcon width="24" height="24" color={color} />;

			const defaultIcon = (
				<ChangeHistoryIcon
					sx={{
						color,
					}}
					fontSize="small"
				/>
			);

			const iconMap: Record<string, JSX.Element> = {
				linux: <LinuxIcon width="20" height="20" color={color} />,
				windows: <WindowsIcon width="18" height="18" color={color} />,
				container: <DockerIcon width="24" height="24" color={color} />,
				darwin: <AppleIcon width="24" height="24" color={color} />,
				AIX: <AIXIcon width="20" height="20" color={color} />,
				solaris: solarisIcon,
				SunOS: solarisIcon,
			};

			return iconMap[osName || ""] || defaultIcon;
		};

		const assetIps = getAssetInterfaceIPs(asset?.interfaces ?? []);
		const ipAddressObj = {
			label: "IP Address",
			value: assetIps,
			icon: (
				<CenterComponent>
					<DnsIcon width="24px" height="24px" sx={iconStyle} />
				</CenterComponent>
			),
		};

		const businessValueObj = {
			label: "Business Value",
			value: asset?.businessValue ?? "",
			icon: (
				<CenterComponent>
					<AttachMoneyIcon width="24px" height="24px" sx={iconStyle} />
				</CenterComponent>
			),
		};

		const operatingSystemObj = {
			label: "Operating System",
			value: asset?.osName ?? "--",
			icon: getOsIcon(asset?.osName ?? ""),
		};

		const defaultFields = [businessValueObj];

		switch (asset?.type) {
			case AssetType.Device:
				const kernelVersion =
					asset?.coreTags?.[CoreTagsKeys.KernelVersion] ?? "--";
				const kernelVersionObj = {
					label: "Version",
					value: kernelVersion,
					icon: (
						<LocalOfferOutlinedIcon width="24" height="24" sx={iconStyle} />
					),
				};

				const serialNumber =
					asset?.coreTags?.[CoreTagsKeys.SerialNumber] ?? "--";
				const serialNumberObj = {
					label: "Serial Number",
					value: serialNumber,
					icon: (
						<LocalOfferOutlinedIcon width="24" height="24" sx={iconStyle} />
					),
				};

				const macAddress =
					(asset?.interfaces && asset?.interfaces[0]?.macaddress) ?? "";
				const macAddressObj = {
					label: "MAC Address",
					value: macAddress,
					icon: (
						<CenterComponent>
							<DnsIcon width="24px" height="24px" sx={iconStyle} />
						</CenterComponent>
					),
				};

				const vendorObj = {
					label: "Vendor",
					value: asset?.vendorInfo,
					icon: (
						<CenterComponent>
							<StoreIcon width="24px" height="24px" sx={iconStyle} />
						</CenterComponent>
					),
				};
				return [
					...defaultFields,
					kernelVersionObj,
					serialNumberObj,
					ipAddressObj,
					macAddressObj,
					vendorObj,
				];
			case AssetType.Service:
				return [...defaultFields, ipAddressObj];
			case AssetType.Server:
			case AssetType.User:
				return [...defaultFields, operatingSystemObj, ipAddressObj];
			default:
				return [];
		}
	}, [asset, iconStyle, theme.palette.mode]);

	const isUserDefinedTagsExist = Boolean(userDefinedTagsList?.length);

	return (
		<>
			<Grid container sx={{ width: "100%" }} columnSpacing={3}>
				{(coreTagList ?? []).map((key: string, index: number) => {
					return (
						<Grid xs={12} sm={6} md={4} lg={4} key={`${key}`}>
							<Item>
								<AssetCoreTag
									asset={asset}
									isUserDefinedTagsExist={isUserDefinedTagsExist}
									coreTagKey={key}
									index={index}
								/>
							</Item>
						</Grid>
					);
				})}
				{fields.map(({ label, value, icon }) => (
					<Grid key={label} xs={12} sm={6} md={4} lg={4}>
						<Item>
							<MetaDataItem
								label={label}
								value={value || "--"}
								icon={icon}
								isUserDefinedTagsExist={isUserDefinedTagsExist}
							/>
						</Item>
					</Grid>
				))}

				{userDefinedTagsList?.map((key: any, index: number) => {
					return (
						<Grid xs={12} sm={6} md={4} lg={4} sx={{ mb: 0 }} key={`${key}`}>
							<Item>
								<AssetCoreTag
									isUserDefinedTagsExist={isUserDefinedTagsExist}
									asset={asset}
									coreTagKey={key}
									index={index}
								/>
							</Item>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
}
