import { DisplayTextMap } from "common/constants/displayTextMap";
import { TIME_FILTER_OPTIONS } from "common/molecules/TimeFilter/TimeFilter";
import {
	FEATURES,
	useFeatureFlagControl,
} from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { FacetGroupInfo } from "modules/core/types";
import { ALL_TIME, COMMON_FACETS } from "modules/facets/constants";
import { FacetGroups } from "modules/facets/types";
import { PortStatus } from "pages/ports/types";
import { useMemo } from "react";

export const StatusOptions: { [key: string]: string } = {
	[PortStatus.Unreviewed]: "Unreviewed",
	[PortStatus.AllowIntranet]: "Allow-Intranet",
	[PortStatus.AllowAny]: "Allow-Any",
	[PortStatus.Deny]: "Deny",
	[PortStatus.PathRestricted]: "Path-Restricted",
};

const BASE_PORTS_FACET_ORDER: FacetGroupInfo = {
	...COMMON_FACETS,
	listenport: FacetGroups.Ports,
	listenportenforced: FacetGroups.Ports,
	listenportreviewed: FacetGroups.Ports,
	listenprocessnames: FacetGroups.Ports,
	listenportprotocol: FacetGroups.Ports,
	listenportname: FacetGroups.Ports,
	listeningonpublicinterface: FacetGroups.Ports,
	portdatavolume: FacetGroups.Ports,
	portconnectioncount: FacetGroups.Ports,
	internetbandwidthinbytes: FacetGroups.Ports,
	intranetbandwidthinbytes: FacetGroups.Ports,
	listenportlastobserved: FacetGroups.Ports,
	portcategory: FacetGroups.Ports,
	internetpathcount: FacetGroups.Paths,
	pathcount: FacetGroups.Paths,
	attacksurface: FacetGroups.Security,
	assetinboundstatus: FacetGroups.Security,
	blastradius: FacetGroups.Security,
	assetoutboundstatus: FacetGroups.Security,
	assetrisk: FacetGroups.Security,
	totalports: FacetGroups.Security,
	totalpaths: FacetGroups.Security,
	assetname: FacetGroups.Assets,
	internetoutboundenforcementready: FacetGroups.Assets,
	intranetoutboundenforcementready: FacetGroups.Assets,
	assetinternetfacing: FacetGroups.Assets,
	namednetworkname: FacetGroups.Network,
	provider: FacetGroups.Network,
	isoobnetwork: FacetGroups.Network,
	agentstatus: FacetGroups.Agents,
	currentversion: FacetGroups.Agents,
	policystatus: FacetGroups.Agents,
	agenttype: FacetGroups.Agents,
	cveid: FacetGroups.Security,
	vulnerabilities: FacetGroups.Security,
	securitypatches: FacetGroups.Security,
	package: FacetGroups.Security,
};

const PORTS_FACET_ORDER: FacetGroupInfo = {
	...BASE_PORTS_FACET_ORDER,
	agentname: FacetGroups.Gatekeepers,
	agentlocation: FacetGroups.Gatekeepers,
};

const PORTS_AND_ENDPOINTS_FACETS: FacetGroupInfo = {
	...BASE_PORTS_FACET_ORDER,
	useremail: FacetGroups.Users,
	groupname: FacetGroups.Groups,
	idpname: FacetGroups.IdP,
};

export const usePortsFacetsOrder = () => {
	const { isFeatureEnabled: isUserGroupFiltersFeatureEnabled } =
		useFeatureFlagControl(FEATURES.USER_GROUP_FILTERS);
	const { isFeatureEnabled: isDevicesFiltersFeatureEnabled } =
		useFeatureFlagControl(FEATURES.DEVICES_FILTERS);

	const FACET_ORDER: FacetGroupInfo = useMemo(() => {
		if (isDevicesFiltersFeatureEnabled && isUserGroupFiltersFeatureEnabled) {
			return {
				...PORTS_FACET_ORDER,
				...PORTS_AND_ENDPOINTS_FACETS,
			};
		}

		if (isDevicesFiltersFeatureEnabled) {
			return PORTS_FACET_ORDER;
		}

		if (isUserGroupFiltersFeatureEnabled) {
			return PORTS_AND_ENDPOINTS_FACETS;
		}

		return BASE_PORTS_FACET_ORDER;
	}, [isDevicesFiltersFeatureEnabled, isUserGroupFiltersFeatureEnabled]);

	return FACET_ORDER;
};

export const StatusOptionsText: { [key: string]: string } = DisplayTextMap;

export const StatusText: { [key: string]: string } = DisplayTextMap;

export const PORT_TIME_FILTER_OPTIONS = [...TIME_FILTER_OPTIONS, ALL_TIME];
