import { useQueryClient } from "@tanstack/react-query";
import { parseErrorMessage } from "common/utils";
import { TextFieldsValues } from "modules/form-fields/type";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	useSubmitAuthConfigAPI,
	useUpdateAuthConfigAPI,
} from "pages/Integration/tabs/authentication/hooks";
import { useCallback, useState } from "react";

export function useIntegrationUpdateConfigFormExtraAfter(configId?: string) {
	const [isLoading, setIsLoading] = useState(false);

	const updateAuthConfigMutation = useUpdateAuthConfigAPI(configId);
	const updateAuthConfigMutate = updateAuthConfigMutation.mutate;
	const submitAuthConfigMutation = useSubmitAuthConfigAPI();
	const submitAuthConfigMutate = submitAuthConfigMutation.mutate;
	const queryClient = useQueryClient();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const updateSamlOrOauthIntegrationConfig = useCallback(
		function (textFieldsValues: TextFieldsValues, onClose?: () => void) {
			setIsLoading(true);

			try {
				if (configId) {
					const body = {
						refreshTokenTTL: textFieldsValues?.refreshTokenTTL ?? 30,
						accessTokenTTL:
							textFieldsValues?.accessTokenTTL &&
							Number(textFieldsValues?.accessTokenTTL),
					};

					updateAuthConfigMutate(body, {
						onSuccess: response => {
							setSnackbar(
								true,
								SnackBarSeverity.Success,
								"AuthConfigurationSaved"
							);
							queryClient.invalidateQueries({
								predicate: (q: any) => {
									let tag: string = q.queryKey[0] as string;
									return tag.startsWith("auth");
								},
							});
							onClose?.();
						},
						onError: error => {
							setSnackbar(
								true,
								SnackBarSeverity.Error,
								parseErrorMessage(error)
							);
						},
					});
				} else {
					const body = {
						authName: "DEFAULT",
						authType: "DEFAULT",
						config: {
							refreshTokenRollingEnabled:
								textFieldsValues?.refreshTokenRollingEnabled,
							refreshTokenTTL: textFieldsValues?.refreshTokenTTL,
							accessTokenTTL:
								textFieldsValues?.accessTokenTTL &&
								Number(textFieldsValues?.accessTokenTTL),
						},
					};

					submitAuthConfigMutate(body, {
						onSuccess: response => {
							setSnackbar(
								true,
								SnackBarSeverity.Success,
								"AuthConfigurationSaved"
							);
							queryClient.invalidateQueries({
								predicate: (q: any) => {
									let tag: string = q.queryKey[0] as string;
									return tag.startsWith("auth");
								},
							});
							onClose?.();
						},
						onError: error => {
							setSnackbar(
								true,
								SnackBarSeverity.Error,
								parseErrorMessage(error)
							);
						},
					});
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		},
		[
			configId,
			queryClient,
			setIsLoading,
			setSnackbar,
			updateAuthConfigMutate,
			submitAuthConfigMutate,
		]
	);

	return { isLoading, updateSamlOrOauthIntegrationConfig };
}
