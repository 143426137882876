import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { getCurrentTenantID } from "hooks/useComplianceController/helper";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { Agent } from "pages/agents/types";
import { AssetType } from "pages/assets/types";
import { useMemo, useState } from "react";
import AgentToolbar from "../agent-data-grid-toolbar/Toolbar";
import { AGENT_DRAWER_BASE_COLUMNS } from "../agent-drawers/helpers/constants";
import {
	AGENT_COLUMNS,
	AgentColumnConfig,
	NS_TRAFFIC_AGENT_COLUMNS,
} from "./constants";
const disableMetricsForTenants: Record<string, boolean> = {
	"": true,
	"9c997397-4862-4582-9285-9ddfd48cca73": true,
	"2bf860ee-75a6-412d-b8b0-469065b3d050": true,
};

export const AgentDataGrid = (props: DataGridProps<Agent>) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const { isFeatureEnabled: isNorthSouthTrafficEnabled } =
		useFeatureFlagControl(FEATURES.NS_TRAFFIC_CONFIG);
	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	let selectedData: Array<Agent> | undefined = useMemo(() => {
		return props.rows?.filter((row: Agent) => {
			return selection.indexOf(`${row?.agentId}-${row?.asset?.assetId}`) !== -1;
		});
	}, [selection, props.rows]);

	const onClickClearSelection = () => {
		setSelection([]);
	};
	let selectedRawData: Array<Agent> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: Agent) => {
			return selection.indexOf(`${row?.agentId}-${row?.asset?.assetId}`) !== -1;
		});
	}, [selection, props?.rawData]);

	const columns = useMemo(() => {
		let cols = userPermissions.has("READ_AGENT_LOG")
			? isNorthSouthTrafficEnabled
				? NS_TRAFFIC_AGENT_COLUMNS()
				: AGENT_COLUMNS()
			: AGENT_DRAWER_BASE_COLUMNS;
		let currentTenantId = getCurrentTenantID();
		if (!currentTenantId || disableMetricsForTenants[currentTenantId]) {
			cols = cols.filter(
				c => c.field !== "cpuutilization" && c.field !== "memoryutilization"
			);
		}

		return cols;
	}, [userPermissions, isNorthSouthTrafficEnabled]);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<AgentToolbar
				hideToolbar={onClickClearSelection}
				show={true}
				selectedData={selectedData}
				selection={selection}
			/>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					defaultPinnedColumns={AgentColumnConfig.PinnedColumns}
					checkboxSelection={userPermissions.has("UPDATE_AGENT")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					rowHeight={64}
					columns={columns}
					pagination
					isRowSelectable={data => data.row?.asset?.type !== AssetType.Service}
					getRowId={({ rowId }: Agent) => rowId!}
					paginationMode="server"
					sortingMode="server"
					{...props}
					selectedRawData={selectedRawData}
				/>
			</Box>
		</Stack>
	);
};
