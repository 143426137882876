import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { AuditLog } from "pages/monitoring/types";
import { useUpdateTotalCount } from "pages/paths/components/path-data-grid/hooks";
import { useMemo, useState } from "react";
import { AuditLogDetails } from "./components";
import { AUDIT_LOG_PINNED_COLUMNS, BASE_AUDIT_LOG_COLUMNS } from "./constants";
import { Box, Stack } from "@mui/material";
import { AuditLogToolBar } from "../audit-datagrid-toolbar";
import { GridRowId } from "@mui/x-data-grid-pro";

export const AUDIT_LOGS_DATA_GRID_ID = "audit-logs-data-grid";

export const AuditLogDataGrid = (props: DataGridProps<AuditLog>) => {
	const [selectedLog, setSelectedLog] = useState<AuditLog | undefined>();

	useUpdateTotalCount({
		isLoading: props.isLoading,
		maxRowCount: props.maxRowCount,
		originalRowCount: props.originalRowCount,
		id: AUDIT_LOGS_DATA_GRID_ID,
	});
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const selectedData: Array<AuditLog> | undefined = useMemo(() => {
		return props.rows?.filter((row: AuditLog) => {
			return selection.indexOf(row?.resourceId) !== -1;
		});
	}, [selection, props.rows]);
	const onClickClearSelection = () => {
		setSelection([]);
	};
	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<AuditLogToolBar
				show={true}
				hideToolbar={onClickClearSelection}
				selection={selectedData}
			/>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					defaultPinnedColumns={AUDIT_LOG_PINNED_COLUMNS}
					rowHeight={64}
					columns={BASE_AUDIT_LOG_COLUMNS}
					pagination
					exportRowCount={props.originalRowCount}
					getRowId={({ id }: AuditLog) => id}
					paginationMode="server"
					sortingMode="server"
					{...props}
					onRowClick={params => {
						setSelectedLog(params.row);
					}}
				/>
				{selectedLog && (
					<AuditLogDetails
						auditLog={selectedLog}
						onClose={() => {
							setSelectedLog(undefined);
						}}
					/>
				)}
			</Box>
		</Stack>
	);
};
