import { UseMutationResult } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { AutoUpgradeStatus } from "pages/agents/components/agent-drawers/helpers/constants";
import { useApplianceStore } from "pages/appliances/store";

interface SubmitApplianceProps {
	selectedItems: string[];
	selectedIds: string;
	mutation: UseMutationResult<any, Error, any, unknown>;
	onCancel: () => void;
	action?: string;
}

export const useSubmitAppliance = () => {
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const requestAPIRefresh = useApplianceStore(store => store.requestAPIRefresh);

	const confirmApplianceRestart = ({
		selectedItems,
		selectedIds: selectedAgentIds,
		mutation,
		onCancel,
	}: SubmitApplianceProps) => {
		if (selectedItems) {
			const applianceRestartBody = {
				criteria: `agentId in (${selectedAgentIds})`,
				version: "@latest",
			};

			mutation.mutateAsync(applianceRestartBody, {
				onSuccess: (response: any) => {
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "AgentRestartRequestSubmittedSuccessfully",
					});
					onCancel();
					requestAPIRefresh();
				},
				onError: (error: any) => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					onCancel();
				},
			});
		}
	};
	const confirmApplianceUpgrade = ({
		selectedItems,
		selectedIds: selectedAgentIds,
		mutation,
		onCancel,
	}: SubmitApplianceProps) => {
		if (selectedItems) {
			const applianceUpgradeBody = {
				criteria: `agentId in (${selectedAgentIds})`,
				version: "@latest",
			};

			mutation.mutateAsync(applianceUpgradeBody, {
				onSuccess: response => {
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "AgentAutoUpgradeRequestSubmittedSuccessfully",
					});
					onCancel();
					requestAPIRefresh();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					onCancel();
				},
			});
		}
	};

	const confirmApplianceAutoUpgrade = ({
		selectedItems,
		selectedIds: selectedAgentIds,
		mutation,
		onCancel,
		action,
	}: SubmitApplianceProps) => {
		if (selectedItems) {
			const applianceAutoUpgradeBody = {
				criteria: `agentId in (${selectedAgentIds})`,
				updateDetails: {
					autoUpgradeEnabled:
						action === AutoUpgradeStatus.Enable ? true : false,
				},
			};
			mutation.mutateAsync(applianceAutoUpgradeBody, {
				onSuccess: response => {
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "AgentAutoUpgradeRequestSubmittedSuccessfully",
					});
					onCancel();
					requestAPIRefresh();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					onCancel();
				},
			});
		}
	};

	const confirmApplianceDiagnostic = ({
		selectedItems,
		selectedIds: selectedAgentIds,
		mutation,
		onCancel,
	}: SubmitApplianceProps) => {
		if (selectedItems) {
			const applianceDiagnosticBody = {
				criteria: `agentId in (${selectedAgentIds})`,
			};
			mutation.mutateAsync(applianceDiagnosticBody, {
				onSuccess: response => {
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "AgentDiagnosticRequestSubmittedSuccessfully",
					});
					onCancel();
					requestAPIRefresh();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					onCancel();
				},
			});
		}
	};

	return {
		confirmApplianceRestart,
		confirmApplianceAutoUpgrade,
		confirmApplianceUpgrade,
		confirmApplianceDiagnostic,
	};
};
