import {
	AGENT_TYPES,
	PLATFORMS,
} from "pages/agents/components/agent-download-page/constants";
import { AgentDefaultType, AgentVersionDetails } from "../type";

export function getAvailablePlatforms(
	platformAgentData: AgentVersionDetails,
	props: AgentDefaultType
) {
	if (props.agentType === AGENT_TYPES.CT_REMOTE_CONNECTOR) {
		return Object.keys(platformAgentData)
			.filter(key => key === PLATFORMS.DEBIAN)
			.sort()
			.reverse();
	} else {
		return Object.keys(platformAgentData)
			.filter(
				key =>
					key !== PLATFORMS.DOCKER &&
					key !== PLATFORMS.LINUX &&
					key !== PLATFORMS.POWERSHELL
			)
			.sort()
			.reverse();
	}
}

export function filterServerUserPlatforms(
	platformAgentData: AgentVersionDetails,
	props: AgentDefaultType,
	isAlpha: boolean
) {
	if (isAlpha) {
		return getAvailablePlatforms(platformAgentData, props);
	} else {
		return getAvailablePlatforms(platformAgentData, props).filter(
			item => item !== PLATFORMS.RPM_LEGACY
		);
	}
}
